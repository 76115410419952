import { PodspecCryptographic } from "./types/cryptographic";
import { PodspecEdDSAPubKey } from "./types/eddsa_pubkey";
import { PodspecEntries } from "./types/entries";
import { PodspecInt } from "./types/int";
import { PodspecPOD } from "./types/pod";
import { PodspecString } from "./types/string";
export const deserialize = PodspecPOD.deserialize;
/**
 * Creation functions for Podspec types.
 */
export const entries = PodspecEntries.create;
export const string = PodspecString.create;
export const int = PodspecInt.create;
export const cryptographic = PodspecCryptographic.create;
export const eddsaPubKey = PodspecEdDSAPubKey.create;
export const pod = PodspecPOD.create;
/**
 * Creation functions for Podspec values with coercion enabled.
 */
export const coerce = {
    string: (args) => string({ ...args, coerce: true }),
    int: (args) => int({ ...args, coerce: true }),
    cryptographic: (args) => cryptographic({ ...args, coerce: true }),
    eddsaPubKey: (args) => eddsaPubKey({ ...args, coerce: true })
};
