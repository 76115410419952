import { checkPODName } from "@pcd/pod";
import { PodspecDataType, PodspecValue } from "../base";
import { IssueCode, PodspecError } from "../error";
import { FAILURE, SUCCESS, isValid } from "../parse";
import { isEqualPODValue } from "../utils";
import { PodspecCryptographic } from "./cryptographic";
import { PodspecEdDSAPubKey } from "./eddsa_pubkey";
import { PodspecInt } from "./int";
import { PodspecOptional } from "./optional";
import { PodspecString } from "./string";
/**
 * Podspec for a set of entries and the checks that should be performed on them.
 */
export class PodspecEntries {
    constructor(def) {
        this.def = def;
        for (const key in this.def.entries) {
            if (!(this.def.entries[key] instanceof PodspecValue)) {
                throw new Error(`Entry ${key} is not an instance of PodspecValue`);
            }
        }
    }
    /**
     * Parses the given data into the output type.
     * Will throw an exception if the data is invalid.
     *
     * @param data - The data to parse.
     * @param params - Optional parameters for the parse operation.
     * @returns The parsed output.
     */
    parse(data, params) {
        const result = this.safeParse(data, params);
        if (isValid(result)) {
            return result.value;
        }
        throw new PodspecError(result.issues);
    }
    /**
     * Parses the given data into the output type, returning a ParseResult.
     * Will not throw an exception, in contrast to {@link parse}.
     *
     * @param data - The data to parse.
     * @param params - Optional parameters for the parse operation.
     * @returns The parse result.
     */
    safeParse(data, params) {
        return this._parse(data, params);
    }
    /**
     * Parses the given data into the output type, returning a ParseResult.
     * Meant for internal use rather than as an external API.
     *
     * @param data - The data to parse.
     * @param params - Optional parameters for the parse operation.
     * @returns The parse result.
     */
    _parse(data, params) {
        const path = params?.path ?? [];
        if (typeof data !== "object") {
            const issue = {
                code: IssueCode.invalid_type,
                expectedType: "object",
                path
            };
            return FAILURE([issue]);
        }
        if (data === null) {
            const issue = {
                code: IssueCode.invalid_type,
                expectedType: "object",
                path
            };
            return FAILURE([issue]);
        }
        const issues = [];
        const result = {};
        for (const key in this.def.entries) {
            if (!(key in data) &&
                !(this.def.entries[key] instanceof PodspecOptional)) {
                const issue = {
                    code: IssueCode.missing_entry,
                    key,
                    path: [...path, key]
                };
                issues.push(issue);
            }
        }
        for (const [key, value] of Object.entries(data)) {
            if (key in this.def.entries) {
                try {
                    // Will throw if the key is invalid
                    checkPODName(key);
                }
                catch (e) {
                    const issue = {
                        code: IssueCode.invalid_entry_name,
                        name: key,
                        description: e.message,
                        path: [...path, key]
                    };
                    issues.push(issue);
                }
                const parsedEntry = this.def.entries[key].safeParse(value, {
                    path: [...path, key]
                });
                // parsed might be undefined if the type is optional
                if (isValid(parsedEntry)) {
                    if (parsedEntry.value !== undefined) {
                        result[key] = parsedEntry.value;
                    }
                }
                else {
                    issues.push(...parsedEntry.issues);
                }
            }
            else {
                // Might want a parsing mode that rejects unexpected values here
                // By default, ignore them and do not include them in the parsed result
            }
        }
        for (const check of this.def.checks) {
            if (check.kind === "tupleMembership") {
                const resultEntryKeys = Object.keys(result);
                const tuple = [];
                let validTuple = true;
                for (const entryKey of check.spec.entries) {
                    if (!resultEntryKeys.includes(entryKey)) {
                        validTuple = false;
                        const issue = {
                            code: IssueCode.invalid_tuple_entry,
                            name: entryKey,
                            path: [...path, entryKey]
                        };
                        issues.push(issue);
                    }
                    tuple.push(result[entryKey]);
                }
                if (!validTuple) {
                    return FAILURE(issues);
                }
                let matched = false;
                for (const tupleToCheck of check.spec.members) {
                    const isMatching = tupleToCheck.every((val, index) => isEqualPODValue(val, tuple[index]));
                    if (isMatching) {
                        if (check.spec.exclude) {
                            const issue = {
                                code: IssueCode.excluded_by_tuple_list,
                                value: tuple,
                                list: check.spec.members,
                                path
                            };
                            issues.push(issue);
                        }
                        else {
                            matched = true;
                            break;
                        }
                    }
                }
                if (!(check.spec.exclude ?? false) && !matched) {
                    const issue = {
                        code: IssueCode.not_in_tuple_list,
                        value: tuple,
                        list: check.spec.members,
                        path
                    };
                    issues.push(issue);
                }
            }
        }
        if (issues.length > 0) {
            return FAILURE(issues);
        }
        return SUCCESS(result);
    }
    /**
     * Adds a tuple membership check to the Podspec.
     *
     * @param spec - The tuple specification.
     * @returns The Podspec with the tuple membership check added.
     */
    tuple(spec) {
        // @todo validate this before adding it
        this.def.checks.push({
            kind: "tupleMembership",
            spec
        });
        return this;
    }
    /**
     * Serializes the Podspec into a cloneable object.
     *
     * The serialized may not be safe to serialize to JSON due to the presence
     * of bigint values.
     *
     * @returns The serialized Podspec.
     */
    serialize() {
        return {
            checks: structuredClone(this.def.checks),
            entries: Object.fromEntries(Object.entries(this.def.entries).map(([key, value]) => [
                key,
                value.serialize()
            ]))
        };
    }
    /**
     * Deserializes a serialized PodspecEntries.
     * Note that this is not the same as deserializing PodspecPOD.
     * See {@link PodspecPOD.deserialize} for that.
     *
     * The initial use of this is for the Z API, where queries must be
     * serialized before being sent to the wallet, and the wallet must be able
     * to deserialize them.
     *
     * @param serialized - The serialized Podspec.
     * @returns The deserialized Podspec.
     */
    static deserialize(serialized) {
        const deserializedEntries = {};
        for (const [key, value] of Object.entries(serialized.entries)) {
            switch (value.type) {
                case PodspecDataType.String:
                    deserializedEntries[key] = PodspecString.create(value);
                    break;
                case PodspecDataType.Int:
                    deserializedEntries[key] = PodspecInt.create(value);
                    break;
                case PodspecDataType.Cryptographic:
                    deserializedEntries[key] = PodspecCryptographic.create(value);
                    break;
                case PodspecDataType.EdDSAPubKey:
                    deserializedEntries[key] = PodspecEdDSAPubKey.create(value);
                    break;
                case PodspecDataType.Optional:
                    deserializedEntries[key] = PodspecOptional.create(value);
                    break;
                default:
                    throw new Error(`Unsupported PodspecDataType: ${value.type}`);
            }
        }
        const podspecEntries = new PodspecEntries({
            entries: deserializedEntries,
            checks: serialized.checks
        });
        return podspecEntries;
    }
    /**
     * Creates a new Podspec from a set of entry specs.
     *
     * @param entries - The entry specs to create the Podspec from.
     * @returns The new Podspec.
     */
    static create(entries) {
        return new PodspecEntries({ entries, checks: [] });
    }
}
