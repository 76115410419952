export const SemaphoreIdentityPCDTypeName = "semaphore-identity-pcd";
export function isSemaphoreIdentityPCD(pcd) {
    return pcd.type === SemaphoreIdentityPCDTypeName;
}
export class SemaphoreIdentityPCD {
    constructor(id, claim) {
        this.type = SemaphoreIdentityPCDTypeName;
        this.claim = claim;
        this.proof = undefined;
        this.id = id;
    }
}
