/**
 * The globally unique type name of the {@link EdDSAPCD}.
 */
export const ObjPCDTypeName = "obj-pcd";
export class ObjPCD {
    constructor(id, claim, proof) {
        this.type = ObjPCDTypeName;
        this.id = id;
        this.claim = claim;
        this.proof = proof;
    }
}
