import { z } from "zod";
import { ZappSchema } from "./zapp.js";
export var WindowMessageType;
(function (WindowMessageType) {
    WindowMessageType["ZUPASS_CLIENT_CONNECT"] = "zupass-client-connect";
})(WindowMessageType || (WindowMessageType = {}));
export var RPCMessageType;
(function (RPCMessageType) {
    RPCMessageType["ZUPASS_CLIENT_INVOKE"] = "zupass-client-invoke";
    RPCMessageType["ZUPASS_CLIENT_INVOKE_RESULT"] = "zupass-client-invoke-result";
    RPCMessageType["ZUPASS_CLIENT_INVOKE_ERROR"] = "zupass-client-invoke-error";
    RPCMessageType["ZUPASS_CLIENT_READY"] = "zupass-client-ready";
    RPCMessageType["ZUPASS_CLIENT_SHOW"] = "zupass-client-show";
    RPCMessageType["ZUPASS_CLIENT_HIDE"] = "zupass-client-hide";
})(RPCMessageType || (RPCMessageType = {}));
export const RPCMessageSchema = z.discriminatedUnion("type", [
    z.object({
        type: z.literal(RPCMessageType.ZUPASS_CLIENT_INVOKE),
        serial: z.number(),
        fn: z.string(),
        args: z.array(z.unknown())
    }),
    z.object({
        type: z.literal(RPCMessageType.ZUPASS_CLIENT_INVOKE_RESULT),
        result: z.unknown(),
        serial: z.number()
    }),
    z.object({
        type: z.literal(RPCMessageType.ZUPASS_CLIENT_INVOKE_ERROR),
        error: z.string(),
        serial: z.number()
    }),
    z.object({
        type: z.literal(RPCMessageType.ZUPASS_CLIENT_READY)
    }),
    z.object({
        type: z.literal(RPCMessageType.ZUPASS_CLIENT_SHOW)
    }),
    z.object({
        type: z.literal(RPCMessageType.ZUPASS_CLIENT_HIDE)
    })
]);
export const WindowMessageSchema = z.discriminatedUnion("type", [
    z.object({
        type: z.literal(WindowMessageType.ZUPASS_CLIENT_CONNECT),
        zapp: ZappSchema
    })
]);
export function postWindowMessage(window, message, targetOrigin, transfer = []) {
    window.postMessage(message, targetOrigin, transfer);
}
export function postRPCMessage(port, message) {
    port.postMessage(message);
}
