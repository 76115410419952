import { jsx as _jsx } from "react/jsx-runtime";
import { getEmailAddress } from "@pcd/email-pcd";
import { styled } from "@pcd/passport-ui";
export const EmailPCDUI = {
    renderCardBody: EmailCardBody
};
function EmailCardBody({ pcd }) {
    const emailAddress = getEmailAddress(pcd);
    return (_jsx(Container, { children: _jsx(EmailInfo, { children: _jsx("span", { children: emailAddress }) }) }));
}
const Container = styled.span `
  padding: 16px;
  overflow: hidden;
  width: 100%;
`;
const EmailInfo = styled.div `
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
