import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { serializeGPCBoundConfig, serializeGPCRevealedClaims } from "@pcd/gpc";
import { GPCPCDPackage } from "@pcd/gpc-pcd";
import { Button, ErrorContainer, FieldLabel, Separator, Spacer, TextContainer, styled } from "@pcd/passport-ui";
import { getErrorMessage } from "@pcd/util";
import { useState } from "react";
export const GPCPCDUI = {
    renderCardBody: GPCCardBody
};
const Container = styled.div `
  padding: 16px;
  overflow: hidden;
  width: 100%;
`;
function GPCCardBody({ pcd }) {
    const [error, setError] = useState();
    const [verifyStatus, setVerifyStatus] = useState(0);
    const proofButtonColor = {};
    if (verifyStatus > 0) {
        proofButtonColor.color = "white";
        proofButtonColor.background = "green";
    }
    else if (verifyStatus < 0) {
        proofButtonColor.color = "white";
        proofButtonColor.background = "var(--danger)";
    }
    return (_jsxs(Container, { children: [_jsxs("p", { children: ["This is a ZK proof of info about PODs using a General Purpose Circuit. You can learn more about PODs and GPCs", " ", _jsx("a", { href: "https://zupass.org/pod", children: "here" }), "."] }), _jsx(Separator, {}), _jsx(FieldLabel, { children: "Proof Config" }), _jsx("p", { children: "This specifies what has been proven and revealed." }), _jsx(TextContainer, { style: { overflowX: "auto" }, children: _jsx("pre", { children: serializeGPCBoundConfig(pcd.claim.config, 2) }) }), _jsx(Spacer, { h: 8 }), _jsx(FieldLabel, { children: "Revealed Claims" }), _jsx("p", { children: "These are the entries and metadata revealed in the proof." }), _jsx(TextContainer, { style: { overflowX: "auto" }, children: _jsx("pre", { children: serializeGPCRevealedClaims(pcd.claim.revealed, 2) }) }), _jsx(Spacer, { h: 8 }), _jsx(Button, { style: "primary", size: "small", onClick: async () => {
                    const verifyResult = await verifyProof(pcd);
                    setError(verifyResult.errorMessage);
                    setVerifyStatus(verifyResult.isValid ? 1 : -1);
                }, styles: { float: "right", ...proofButtonColor }, children: verifyStatus === 0
                    ? "Check proof"
                    : verifyStatus > 0
                        ? "Valid proof"
                        : error !== undefined
                            ? "Verification error!"
                            : "Invalid proof!" }), error === undefined ? null : _jsx(ErrorContainer, { children: error })] }));
}
async function verifyProof(pcd) {
    try {
        const isValid = await GPCPCDPackage.verify(pcd);
        return { isValid };
    }
    catch (e) {
        return { isValid: false, errorMessage: getErrorMessage(e) };
    }
}
