import { derivePublicKey, packPublicKey, packSignature, signMessage, unpackPublicKey, unpackSignature, verifySignature } from "@zk-kit/eddsa-poseidon";
import { leBigIntToBuffer, leBufferToBigInt } from "@zk-kit/utils";
import { sha256 } from "js-sha256";
import { poseidon1 } from "poseidon-lite/poseidon1";
import { poseidon2 } from "poseidon-lite/poseidon2";
import { PRIVATE_KEY_REGEX, PUBLIC_KEY_ENCODING_GROUPS, PUBLIC_KEY_REGEX, SIGNATURE_ENCODING_GROUPS, SIGNATURE_REGEX } from "./podChecks.js";
import { EDDSA_PUBKEY_TYPE_STRING } from "./podTypes.js";
import { decodeBytesAuto, encodeBytes } from "./podUtil.js";
/**
 * Calculates the appropriate hash for a POD value represented as a string,
 * which could be one of multiple value types (see {@link podValueHash}).
 */
export function podStringHash(input) {
    // TODO(POD-P2): Finalize choice of hash for POD names and string values.
    return BigInt("0x" + sha256(input)) >> 8n;
}
/**
 * Calculates the appropriate hash for a POD value represented as an integer,
 * which could be one of multiple value types (see {@link podValueHash}).
 */
export function podIntHash(input) {
    // TODO(POD-P2): Finalize choice of hash for POD integer values.
    return poseidon1([input]);
}
/**
 * Calculates the appropriate hash for a POD value represented as a string-encoded EdDSA public key.
 */
export function podEdDSAPublicKeyHash(input) {
    return poseidon2(decodePublicKey(input));
}
/**
 * Calculates the appropriate hash for a POD entry name.
 */
export function podNameHash(podName) {
    return podStringHash(podName);
}
/**
 * Calculates the appropriate hash for a POD value of any type.
 */
export function podValueHash(podValue) {
    switch (podValue.type) {
        case "string":
            return podStringHash(podValue.value);
        case "int":
        case "cryptographic":
            // TODO(POD-P2): Finalize choice of hash for POD cryptographics.
            return podIntHash(podValue.value);
        case EDDSA_PUBKEY_TYPE_STRING:
            return podEdDSAPublicKeyHash(podValue.value);
        default:
            throw new TypeError(`Unexpected type in PODValue ${podValue}.`);
    }
}
/**
 * The hash function used to generate interior nodes in the Merkle tree
 * representing a POD.  The inputs may be value hashes, or other inner
 * nodes.
 */
export function podMerkleTreeHash(left, right) {
    return poseidon2([left, right]);
}
/**
 * Encodes a private key to a string.  The input must be 32 bytes.  The output
 * is represented as unpadded Base64 by default.
 *
 * @param rawPrivateKey the private key bytes
 * @param encoding one of the supported encodings to use, as per
 *   {@link encodeBytes}.
 * @throws TypeError if the size of the buffer is incorrect.
 */
export function encodePrivateKey(rawPrivateKey, encoding = "base64") {
    if (!(rawPrivateKey instanceof Uint8Array)) {
        throw TypeError("Private key must be Buffer or Uint8Array.");
    }
    if (rawPrivateKey.length !== 32) {
        throw TypeError("Private key must be a 32 bytes.");
    }
    return encodeBytes(rawPrivateKey, encoding);
}
/**
 * Decodes a private key's bytes from a string.  The input must be 32 bytes,
 * represented as hex or Base64.  Base64 padding is optional.
 *
 * @param privateKey the private key string to decode
 * @throws TypeError if the private key format is incorrect.
 */
export function decodePrivateKey(privateKey) {
    return decodeBytesAuto(privateKey, PRIVATE_KEY_REGEX, PUBLIC_KEY_ENCODING_GROUPS, "Private key should be 32 bytes, encoded as hex or Base64.");
}
/**
 * Encodes an EdDSA public key into a compact string represenation.  The output
 * is 32 bytes, represented as unpadded Base64 by default.
 *
 * @param rawPublicKey the EdDSA public key to encode
 * @param encoding one of the supported encodings to use
 */
export function encodePublicKey(rawPublicKey, encoding = "base64") {
    return encodeBytes(leBigIntToBuffer(packPublicKey(rawPublicKey), 32), encoding);
}
/**
 * Decodes a public key packed by {@encodePublicKey}.  The input must be
 * 32 bytes, represented as hex or Base64.  Base64 padding is optional.
 *
 * @param publicKey the public key string to decode
 * @throws TypeError if the public key format is incorrect.
 */
export function decodePublicKey(publicKey) {
    const rawPublicKey = unpackPublicKey(leBufferToBigInt(decodeBytesAuto(publicKey, PUBLIC_KEY_REGEX, PUBLIC_KEY_ENCODING_GROUPS, "Public key should be 32 bytes, encoded as hex or Base64.")));
    if (rawPublicKey === null) {
        throw new TypeError(`Invalid packed public key point ${publicKey}.`);
    }
    return rawPublicKey;
}
/**
 * Encodes an EdDSA signature into a compact string representation.
 * The output is represented in unpadded Base64 by default.
 *
 * @param rawSignature the EdDSA signature to encode
 * @param encoding one of the supported encodings to use
 */
export function encodeSignature(rawSignature, encoding = "base64") {
    return encodeBytes(packSignature(rawSignature), encoding);
}
/**
 * Decodes a signature produced by {@link encodeSignature}.  The input must be
 * 64 bytes, represented as hex or Base64.  Base64 padding is optional.
 *
 * @param encodedSignature the signature string to decode
 * @throws TypeError if the signature format is incorrect
 */
export function decodeSignature(encodedSignature) {
    return unpackSignature(decodeBytesAuto(encodedSignature, SIGNATURE_REGEX, SIGNATURE_ENCODING_GROUPS, "Signature should be 64 bytes, encoded as hex or Base64."));
}
/**
 * Signs a POD's root hash.
 *
 * @param root the root hash (content ID) of the POD.
 * @param privateKey the signer's private key, which is 32 bytes encoded as
 *   per {@link encodePrivateKey}.
 * @returns The signature as well as the signer's public key for inclusion
 *   in the POD.  The signature is 64 bytes represented in unpadded Base64.
 * @throws TypeError if any of the individual arguments is incorrectly formatted
 */
export function signPODRoot(root, privateKey) {
    if (typeof root !== "bigint") {
        throw new TypeError("POD root must be a bigint not `${typeof root}`.");
    }
    const privateKeyBytes = decodePrivateKey(privateKey);
    const unpackedSignature = signMessage(privateKeyBytes, root);
    const signature = encodeSignature(unpackedSignature);
    const unpackedPublicKey = derivePublicKey(privateKeyBytes);
    const publicKey = encodePublicKey(unpackedPublicKey);
    return { signature, publicKey };
}
/**
 * Verifies the signature of a POD root hash.
 *
 * @param root the root hash (content ID) of the POD.
 * @param signature the signature in packed form, which is 64 bytes represented
 *   in hex or Base64.  Base64 padding is optional.
 * @param publicKey the signer's public key in packed form, which is 32 bytes
 *   represented in hex or Base64.  Base64 padding is optional.
 * @returns `true` if the signature is valid
 * @throws TypeError if any of the individual arguments incorrectly formatted
 */
export function verifyPODRootSignature(root, signature, publicKey) {
    const unpackedPublicKey = decodePublicKey(publicKey);
    const unpackedSignature = decodeSignature(signature);
    return verifySignature(root, unpackedSignature, unpackedPublicKey);
}
