/**
 * The globally unique type name of the {@link EdDSAPCD}.
 */
export const EdDSAPCDTypeName = "eddsa-pcd";
/**
 * The EdDSA PCD enables the verification that a specific message has been signed with an
 * EdDSA private key. The {@link EdDSAPCDProof}, serving as the signature, is verified
 * using the {@link EdDSAPCDClaim}, which consists of the EdDSA public key and the message.
 */
export class EdDSAPCD {
    constructor(id, claim, proof) {
        this.type = EdDSAPCDTypeName;
        this.id = id;
        this.claim = claim;
        this.proof = proof;
    }
}
