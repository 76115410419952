/**
 * The globally unique type name of the {@link EdDSATicketPCD}.
 */
export const EdDSATicketPCDTypeName = "eddsa-ticket-pcd";
/**
 * Assigns each currently supported category a unique value.
 */
export var TicketCategory;
(function (TicketCategory) {
    TicketCategory[TicketCategory["ZuConnect"] = 0] = "ZuConnect";
    TicketCategory[TicketCategory["Devconnect"] = 1] = "Devconnect";
    TicketCategory[TicketCategory["PcdWorkingGroup"] = 2] = "PcdWorkingGroup";
    TicketCategory[TicketCategory["Zuzalu"] = 3] = "Zuzalu";
    TicketCategory[TicketCategory["Generic"] = 4] = "Generic";
})(TicketCategory || (TicketCategory = {}));
/**
 * The EdDSA Ticket PCD enables the verification that a specific ticket ({@link EdDSATicketPCDClaim})
 * has been signed with an EdDSA private key. The {@link EdDSATicketPCDProof} contains a EdDSA
 * PCD and serves as the signature.
 */
export class EdDSATicketPCD {
    constructor(id, claim, proof) {
        this.type = EdDSATicketPCDTypeName;
        this.id = id;
        this.claim = claim;
        this.proof = proof;
    }
}
/**
 * Returns true if a PCD is an EdDSA Ticket PCD, or false otherwise.
 */
export function isEdDSATicketPCD(pcd) {
    return pcd.type === EdDSATicketPCDTypeName;
}
/**
 * Throws if {@link pcd} is not an {@link EdDSATicketPCD}.
 */
export function expectIsEdDSATicketPCD(pcd) {
    if (pcd.type !== EdDSATicketPCDTypeName) {
        throw new Error("Expected EdDSATicketPCD");
    }
}
