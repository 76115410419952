import { Group } from "@semaphore-protocol/group";
export function serializeSemaphoreGroup(group, name) {
    return {
        id: group.id.toString(),
        name,
        members: group.members.map((m) => m.toString()),
        depth: group.depth,
        zeroValue: group.zeroValue.toString()
    };
}
export function deserializeSemaphoreGroup(serializedGroup) {
    const group = new Group(BigInt(serializedGroup.id), serializedGroup.depth, serializedGroup.members.map((m) => BigInt(m)));
    return group;
}
