[
    [
        {
            "maxObjects": 1,
            "maxEntries": 1,
            "merkleMaxDepth": 5,
            "maxNumericValues": 0,
            "maxLists": 0,
            "maxListElements": 0,
            "maxTuples": 0,
            "tupleArity": 0
        },
        6608
    ],
    [
        {
            "maxObjects": 1,
            "maxEntries": 5,
            "merkleMaxDepth": 6,
            "maxNumericValues": 2,
            "maxLists": 0,
            "maxListElements": 0,
            "maxTuples": 0,
            "tupleArity": 0
        },
        13575
    ],
    [
        {
            "maxObjects": 3,
            "maxEntries": 10,
            "merkleMaxDepth": 8,
            "maxNumericValues": 2,
            "maxLists": 1,
            "maxListElements": 10,
            "maxTuples": 2,
            "tupleArity": 2
        },
        35780
    ],
    [
        {
            "maxObjects": 3,
            "maxEntries": 10,
            "merkleMaxDepth": 8,
            "maxNumericValues": 4,
            "maxLists": 2,
            "maxListElements": 20,
            "maxTuples": 1,
            "tupleArity": 4
        },
        36393
    ],
    [
        {
            "maxObjects": 3,
            "maxEntries": 10,
            "merkleMaxDepth": 8,
            "maxNumericValues": 4,
            "maxLists": 4,
            "maxListElements": 20,
            "maxTuples": 5,
            "tupleArity": 2
        },
        37628
    ]
]
