import { requireDefinedParameter } from "@pcd/util";
import JSONBig from "json-bigint";
import NodeRSA from "node-rsa";
import { v4 as uuid } from "uuid";
import { RSAPCD, RSAPCDTypeName } from "./RSAPCD.js";
export async function prove(args) {
    if (args.privateKey.value === undefined || args.privateKey.value === "") {
        throw new Error("missing private key value");
    }
    if (args.signedMessage.value === undefined) {
        throw new Error("missing message to sign");
    }
    const id = typeof args.id.value === "string" ? args.id.value : uuid();
    const key = new NodeRSA(args.privateKey.value);
    const publicKey = key.exportKey("public");
    const signature = key.sign(args.signedMessage.value, "hex");
    return new RSAPCD(id, { message: args.signedMessage.value }, { publicKey, signature });
}
export async function verify(pcd) {
    try {
        const publicKey = new NodeRSA(pcd.proof.publicKey, "public");
        const valid = publicKey.verify(Buffer.from(pcd.claim.message), pcd.proof.signature, "utf8", "hex");
        return valid;
    }
    catch (e) {
        return false;
    }
}
export async function serialize(pcd) {
    return {
        type: RSAPCDTypeName,
        pcd: JSONBig().stringify(pcd)
    };
}
export async function deserialize(serialized) {
    const { id, claim, proof } = JSONBig().parse(serialized);
    requireDefinedParameter(id, "id");
    requireDefinedParameter(claim, "claim");
    requireDefinedParameter(proof, "proof");
    return new RSAPCD(id, claim, proof);
}
export function getDisplayOptions(pcd) {
    return {
        header: "RSA Signature",
        displayName: "rsa-sig-" + pcd.id.substring(0, 4)
    };
}
/**
 * PCD-conforming wrapper to sign messages using an RSA keypair.
 */
export const RSAPCDPackage = {
    name: RSAPCDTypeName,
    getDisplayOptions,
    prove,
    verify,
    serialize,
    deserialize
};
