/**
 * The globally unique type name of the {@link UnknownPCD}.
 */
export const UnknownPCDTypeName = "unknown-pcd";
/**
 * The UnknownPCD is a wrapper intended to wrap some other PCD which cannot be
 * properly deserialized into its normal PCD form.  The wrapper holds the
 * original data unchanged.
 *
 * When the wrapper is serialized again, it outputs the original serialized PCD
 * in its original form, with its original type.  This is a recovery
 * mechanism for bugs and compatibility problems, which allows the
 * data to be maintained so that it can be deserialized again later by
 * an updated version of code with fixes, or with a new PCD package.
 */
export class UnknownPCD {
    /**
     * Create an UnknownPCD to encapsulate the serialized PCD, optionally
     * including the caught deserialization error.
     */
    constructor(id, serializedPCD, error) {
        this.type = UnknownPCDTypeName;
        this.id = id;
        this.claim = { serializedPCD, error };
        this.proof = {};
    }
}
/**
 * Convenience function for checking the type of a UnknownPCD.
 */
export function isUnknownPCD(pcd) {
    return pcd.type === UnknownPCDTypeName;
}
