import { PCDCrypto } from "@pcd/passport-crypto";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { bigintToUint8Array, uint8arrayToBigint } from "../../../src/util";

export function useUsernameGenerator():
  | ((sempahoreId: string, lowercase?: boolean) => string)
  | null {
  const [pcdCrypto, setPCDCrypto] = useState<PCDCrypto | null>(null);
  useEffect(() => {
    PCDCrypto.newInstance().then(setPCDCrypto);
  }, []);

  const generator = useCallback(
    (sempahoreId: string, lowercase = false) => {
      try {
        if (!pcdCrypto) {
          throw new Error("pcdCrypto is not initialized");
        }

        const randomBytes = pcdCrypto.randombytesDeterministic(
          32,
          bigintToUint8Array(BigInt(sempahoreId))
        );
        if (randomBytes === null) {
          throw new Error("Could not generate random data");
        }
        const randomBigInt = uint8arrayToBigint(randomBytes);

        const randomAdjective: string =
          adjectives[
            Number(
              (randomBigInt / BigInt(animals.length)) %
                BigInt(adjectives.length)
            )
          ];
        const randomAnimal: string =
          animals[Number(randomBigInt % BigInt(animals.length))];

        return lowercase
          ? randomAdjective + "_" + randomAnimal
          : _.startCase(`${randomAdjective} ${randomAnimal}`);
      } catch (e) {
        console.debug("Error in useUsernameGenerator", e);
        return "An Unknown Toad";
      }
    },
    [pcdCrypto]
  );

  return pcdCrypto ? generator : null;
}

export const adjectives = [
  "abandoned",
  "able",
  "absolute",
  "adorable",
  "adventurous",
  "academic",
  "acceptable",
  "acclaimed",
  "accomplished",
  "accurate",
  "aching",
  "acidic",
  "acrobatic",
  "active",
  "actual",
  "adept",
  "admirable",
  "admired",
  "adolescent",
  "adorable",
  "adored",
  "advanced",
  "afraid",
  "affectionate",
  "aged",
  "aggravating",
  "aggressive",
  "agile",
  "agitated",
  "agonizing",
  "agreeable",
  "ajar",
  "alarmed",
  "alarming",
  "alert",
  "alienated",
  "alive",
  "all",
  "altruistic",
  "amazing",
  "ambitious",
  "ample",
  "amused",
  "amusing",
  "anchored",
  "ancient",
  "angelic",
  "angry",
  "anguished",
  "animated",
  "annual",
  "another",
  "antique",
  "anxious",
  "any",
  "apprehensive",
  "appropriate",
  "apt",
  "arctic",
  "arid",
  "aromatic",
  "artistic",
  "ashamed",
  "assured",
  "astonishing",
  "athletic",
  "attached",
  "attentive",
  "attractive",
  "austere",
  "authentic",
  "authorized",
  "automatic",
  "avaricious",
  "average",
  "aware",
  "awesome",
  "awful",
  "awkward",
  "babyish",
  "bad",
  "back",
  "baggy",
  "bare",
  "barren",
  "basic",
  "beautiful",
  "belated",
  "beloved",
  "beneficial",
  "better",
  "best",
  "bewitched",
  "big",
  "biodegradable",
  "bitter",
  "black",
  "bland",
  "blank",
  "blaring",
  "bleak",
  "blind",
  "blissful",
  "blond",
  "blue",
  "blushing",
  "bogus",
  "boiling",
  "bold",
  "bony",
  "boring",
  "bossy",
  "both",
  "bouncy",
  "bountiful",
  "bowed",
  "brave",
  "breakable",
  "brief",
  "bright",
  "brilliant",
  "brisk",
  "broken",
  "bronze",
  "brown",
  "bruised",
  "bubbly",
  "bulky",
  "bumpy",
  "buoyant",
  "burdensome",
  "burly",
  "bustling",
  "busy",
  "buttery",
  "buzzing",
  "calculating",
  "calm",
  "candid",
  "canine",
  "capital",
  "carefree",
  "careful",
  "careless",
  "caring",
  "cautious",
  "cavernous",
  "celebrated",
  "charming",
  "cheap",
  "cheerful",
  "cheery",
  "chief",
  "chilly",
  "chubby",
  "circular",
  "classic",
  "clean",
  "clear",
  "clever",
  "close",
  "closed",
  "cloudy",
  "clueless",
  "clumsy",
  "cluttered",
  "coarse",
  "cold",
  "colorful",
  "colorless",
  "colossal",
  "comfortable",
  "common",
  "compassionate",
  "competent",
  "complete",
  "complex",
  "complicated",
  "composed",
  "concerned",
  "concrete",
  "confused",
  "conscious",
  "considerate",
  "constant",
  "content",
  "conventional",
  "cooked",
  "cool",
  "cooperative",
  "coordinated",
  "corny",
  "corrupt",
  "costly",
  "courageous",
  "courteous",
  "crafty",
  "crazy",
  "creamy",
  "creative",
  "creepy",
  "criminal",
  "crisp",
  "critical",
  "crooked",
  "crowded",
  "cruel",
  "crushing",
  "cuddly",
  "cultivated",
  "cultured",
  "cumbersome",
  "curly",
  "curvy",
  "cute",
  "cylindrical",
  "damaged",
  "damp",
  "dangerous",
  "dapper",
  "daring",
  "darling",
  "dark",
  "dazzling",
  "dead",
  "deadly",
  "deafening",
  "dear",
  "dearest",
  "decent",
  "decimal",
  "decisive",
  "defenseless",
  "defensive",
  "defiant",
  "deficient",
  "definite",
  "definitive",
  "delayed",
  "delectable",
  "delicious",
  "delightful",
  "delirious",
  "demanding",
  "dense",
  "dental",
  "dependable",
  "dependent",
  "descriptive",
  "deserted",
  "detailed",
  "determined",
  "devoted",
  "different",
  "difficult",
  "digital",
  "diligent",
  "dim",
  "dimpled",
  "dimwitted",
  "direct",
  "disastrous",
  "discrete",
  "disloyal",
  "dismal",
  "distant",
  "downright",
  "dreary",
  "dirty",
  "disguised",
  "dishonest",
  "dismal",
  "distant",
  "distinct",
  "distorted",
  "dizzy",
  "dopey",
  "doting",
  "double",
  "downright",
  "drab",
  "drafty",
  "dramatic",
  "dreary",
  "droopy",
  "dry",
  "dual",
  "dull",
  "dutiful",
  "each",
  "eager",
  "earnest",
  "early",
  "easy",
  "ecstatic",
  "edible",
  "educated",
  "elaborate",
  "elastic",
  "elated",
  "elderly",
  "electric",
  "elegant",
  "elementary",
  "elliptical",
  "embarrassed",
  "embellished",
  "eminent",
  "emotional",
  "empty",
  "enchanted",
  "enchanting",
  "energetic",
  "enlightened",
  "enormous",
  "enraged",
  "entire",
  "envious",
  "equal",
  "equatorial",
  "essential",
  "esteemed",
  "ethical",
  "euphoric",
  "even",
  "evergreen",
  "everlasting",
  "every",
  "evil",
  "exalted",
  "excellent",
  "exemplary",
  "exhausted",
  "excitable",
  "excited",
  "exciting",
  "exotic",
  "expensive",
  "experienced",
  "expert",
  "extraneous",
  "extroverted",
  "fabulous",
  "failing",
  "faint",
  "fair",
  "faithful",
  "fake",
  "false",
  "familiar",
  "famous",
  "fancy",
  "fantastic",
  "far",
  "faraway",
  "fast",
  "fat",
  "fatal",
  "fatherly",
  "favorable",
  "favorite",
  "fearful",
  "fearless",
  "feisty",
  "feline",
  "few",
  "fickle",
  "filthy",
  "fine",
  "finished",
  "firm",
  "first",
  "firsthand",
  "fitting",
  "fixed",
  "flaky",
  "flamboyant",
  "flashy",
  "flat",
  "flawed",
  "flawless",
  "flickering",
  "flimsy",
  "flippant",
  "flowery",
  "fluffy",
  "fluid",
  "flustered",
  "focused",
  "fond",
  "foolhardy",
  "foolish",
  "forceful",
  "forked",
  "formal",
  "forsaken",
  "forthright",
  "fortunate",
  "fragrant",
  "frail",
  "frank",
  "frayed",
  "free",
  "French",
  "fresh",
  "frequent",
  "friendly",
  "frightened",
  "frightening",
  "frigid",
  "frilly",
  "frizzy",
  "frivolous",
  "front",
  "frosty",
  "frozen",
  "frugal",
  "fruitful",
  "full",
  "fumbling",
  "functional",
  "funny",
  "fussy",
  "fuzzy",
  "gargantuan",
  "gaseous",
  "general",
  "generous",
  "gentle",
  "genuine",
  "giant",
  "giddy",
  "gigantic",
  "gifted",
  "giving",
  "glamorous",
  "glaring",
  "glass",
  "gleaming",
  "gleeful",
  "glistening",
  "glittering",
  "gloomy",
  "glorious",
  "glossy",
  "glum",
  "golden",
  "good",
  "gorgeous",
  "graceful",
  "gracious",
  "grand",
  "grandiose",
  "granular",
  "grateful",
  "grave",
  "gray",
  "great",
  "greedy",
  "green",
  "gregarious",
  "grim",
  "grimy",
  "gripping",
  "grizzled",
  "gross",
  "grotesque",
  "grouchy",
  "grounded",
  "growing",
  "growling",
  "grown",
  "grubby",
  "gruesome",
  "grumpy",
  "guilty",
  "gullible",
  "gummy",
  "hairy",
  "half",
  "handmade",
  "handsome",
  "handy",
  "happy",
  "hard",
  "harmful",
  "harmless",
  "harmonious",
  "harsh",
  "hasty",
  "hateful",
  "haunting",
  "healthy",
  "heartfelt",
  "hearty",
  "heavenly",
  "heavy",
  "hefty",
  "helpful",
  "helpless",
  "hidden",
  "hideous",
  "high",
  "hilarious",
  "hoarse",
  "hollow",
  "homely",
  "honest",
  "honorable",
  "honored",
  "hopeful",
  "horrible",
  "hospitable",
  "hot",
  "huge",
  "humble",
  "humiliating",
  "humming",
  "humongous",
  "hungry",
  "hurtful",
  "husky",
  "icky",
  "icy",
  "ideal",
  "idealistic",
  "identical",
  "idle",
  "idiotic",
  "idolized",
  "ignorant",
  "ill",
  "illegal",
  "illiterate",
  "illustrious",
  "imaginary",
  "imaginative",
  "immaculate",
  "immaterial",
  "immediate",
  "immense",
  "impassioned",
  "impeccable",
  "impartial",
  "imperfect",
  "imperturbable",
  "impish",
  "impolite",
  "important",
  "impossible",
  "impractical",
  "impressionable",
  "impressive",
  "improbable",
  "impure",
  "inborn",
  "incomparable",
  "incompatible",
  "incomplete",
  "inconsequential",
  "incredible",
  "indelible",
  "inexperienced",
  "indolent",
  "infamous",
  "infantile",
  "infatuated",
  "inferior",
  "infinite",
  "informal",
  "innocent",
  "insecure",
  "insidious",
  "insignificant",
  "insistent",
  "instructive",
  "insubstantial",
  "intelligent",
  "intent",
  "intentional",
  "interesting",
  "internal",
  "international",
  "intrepid",
  "ironclad",
  "irresponsible",
  "irritating",
  "itchy",
  "jaded",
  "jagged",
  "jaunty",
  "jealous",
  "jittery",
  "joint",
  "jolly",
  "jovial",
  "joyful",
  "joyous",
  "jubilant",
  "judicious",
  "juicy",
  "jumbo",
  "junior",
  "jumpy",
  "juvenile",
  "kaleidoscopic",
  "keen",
  "key",
  "kind",
  "kindhearted",
  "kindly",
  "klutzy",
  "knobby",
  "knotty",
  "knowledgeable",
  "knowing",
  "known",
  "kooky",
  "lame",
  "lanky",
  "large",
  "last",
  "lasting",
  "late",
  "lavish",
  "lawful",
  "lazy",
  "leading",
  "lean",
  "leafy",
  "left",
  "legal",
  "legitimate",
  "light",
  "lighthearted",
  "likable",
  "likely",
  "limited",
  "limp",
  "limping",
  "linear",
  "lined",
  "liquid",
  "little",
  "live",
  "lively",
  "livid",
  "loathsome",
  "lone",
  "lonely",
  "long",
  "loose",
  "lopsided",
  "lost",
  "loud",
  "lovable",
  "lovely",
  "loving",
  "low",
  "loyal",
  "lucky",
  "lumbering",
  "luminous",
  "lumpy",
  "lustrous",
  "luxurious",
  "mad",
  "magnificent",
  "majestic",
  "major",
  "mammoth",
  "married",
  "marvelous",
  "massive",
  "mature",
  "meager",
  "mealy",
  "mean",
  "measly",
  "meaty",
  "medical",
  "mediocre",
  "medium",
  "meek",
  "mellow",
  "melodic",
  "memorable",
  "menacing",
  "merry",
  "messy",
  "metallic",
  "mild",
  "milky",
  "mindless",
  "miniature",
  "minor",
  "minty",
  "miserable",
  "miserly",
  "misguided",
  "misty",
  "mixed",
  "modern",
  "modest",
  "monstrous",
  "monthly",
  "monumental",
  "moral",
  "mortified",
  "motherly",
  "motionless",
  "mountainous",
  "muddy",
  "muffled",
  "multicolored",
  "mundane",
  "murky",
  "mushy",
  "musty",
  "muted",
  "mysterious",
  "naive",
  "narrow",
  "nasty",
  "natural",
  "naughty",
  "nautical",
  "near",
  "neat",
  "necessary",
  "needy",
  "negative",
  "neglected",
  "negligible",
  "neighboring",
  "nervous",
  "new",
  "next",
  "nice",
  "nifty",
  "nimble",
  "nippy",
  "nocturnal",
  "noisy",
  "nonstop",
  "normal",
  "notable",
  "noted",
  "noteworthy",
  "novel",
  "noxious",
  "numb",
  "nutritious",
  "nutty",
  "obedient",
  "obese",
  "oblong",
  "oily",
  "oblong",
  "obvious",
  "occasional",
  "odd",
  "oddball",
  "offbeat",
  "offensive",
  "official",
  "old",
  "only",
  "open",
  "optimal",
  "optimistic",
  "opulent",
  "orange",
  "orderly",
  "organic",
  "ornate",
  "ornery",
  "ordinary",
  "original",
  "other",
  "our",
  "outlying",
  "outgoing",
  "outlandish",
  "outrageous",
  "outstanding",
  "oval",
  "overcooked",
  "overdue",
  "overjoyed",
  "overlooked",
  "palatable",
  "pale",
  "paltry",
  "parallel",
  "parched",
  "partial",
  "passionate",
  "past",
  "pastel",
  "peaceful",
  "peppery",
  "perfect",
  "perfumed",
  "periodic",
  "perky",
  "personal",
  "pertinent",
  "pesky",
  "pessimistic",
  "petty",
  "phony",
  "physical",
  "piercing",
  "pink",
  "pitiful",
  "plain",
  "plaintive",
  "plastic",
  "playful",
  "pleasant",
  "pleased",
  "pleasing",
  "plump",
  "plush",
  "polished",
  "polite",
  "political",
  "pointed",
  "pointless",
  "poised",
  "poor",
  "popular",
  "portly",
  "posh",
  "positive",
  "possible",
  "potable",
  "powerful",
  "powerless",
  "practical",
  "precious",
  "present",
  "prestigious",
  "pretty",
  "precious",
  "previous",
  "pricey",
  "prickly",
  "primary",
  "prime",
  "pristine",
  "private",
  "prize",
  "probable",
  "productive",
  "profitable",
  "profuse",
  "proper",
  "proud",
  "prudent",
  "punctual",
  "pungent",
  "puny",
  "pure",
  "purple",
  "pushy",
  "putrid",
  "puzzled",
  "puzzling",
  "quaint",
  "qualified",
  "quarrelsome",
  "quarterly",
  "queasy",
  "querulous",
  "questionable",
  "quick",
  "quiet",
  "quintessential",
  "quirky",
  "quixotic",
  "quizzical",
  "radiant",
  "ragged",
  "rapid",
  "rare",
  "rash",
  "raw",
  "recent",
  "reckless",
  "rectangular",
  "ready",
  "real",
  "realistic",
  "reasonable",
  "red",
  "reflecting",
  "regal",
  "regular",
  "reliable",
  "relieved",
  "remarkable",
  "remorseful",
  "remote",
  "repentant",
  "required",
  "respectful",
  "responsible",
  "repulsive",
  "revolving",
  "rewarding",
  "rich",
  "rigid",
  "right",
  "ringed",
  "ripe",
  "roasted",
  "robust",
  "rosy",
  "rotating",
  "rotten",
  "rough",
  "round",
  "rowdy",
  "royal",
  "rubbery",
  "rundown",
  "ruddy",
  "rude",
  "runny",
  "rural",
  "rusty",
  "sad",
  "safe",
  "salty",
  "same",
  "sandy",
  "sane",
  "sarcastic",
  "sardonic",
  "satisfied",
  "scaly",
  "scarce",
  "scared",
  "scary",
  "scented",
  "scholarly",
  "scientific",
  "scornful",
  "scratchy",
  "scrawny",
  "second",
  "secondary",
  "secret",
  "selfish",
  "sentimental",
  "separate",
  "serene",
  "serious",
  "serpentine",
  "several",
  "severe",
  "shabby",
  "shadowy",
  "shady",
  "shallow",
  "shameful",
  "shameless",
  "sharp",
  "shimmering",
  "shiny",
  "shocked",
  "shocking",
  "shoddy",
  "short",
  "showy",
  "shrill",
  "shy",
  "sick",
  "silent",
  "silky",
  "silly",
  "silver",
  "similar",
  "simple",
  "simplistic",
  "sinful",
  "single",
  "sizzling",
  "skeletal",
  "skinny",
  "sleepy",
  "slight",
  "slim",
  "slimy",
  "slippery",
  "slow",
  "slushy",
  "small",
  "smart",
  "smoggy",
  "smooth",
  "smug",
  "snappy",
  "snarling",
  "sneaky",
  "sniveling",
  "snoopy",
  "sociable",
  "soft",
  "soggy",
  "solid",
  "somber",
  "some",
  "spherical",
  "sophisticated",
  "sore",
  "sorrowful",
  "soulful",
  "soupy",
  "sour",
  "sparkling",
  "sparse",
  "specific",
  "spectacular",
  "speedy",
  "spicy",
  "spiffy",
  "spirited",
  "spiteful",
  "splendid",
  "spotless",
  "spotted",
  "spry",
  "square",
  "squeaky",
  "squiggly",
  "stable",
  "staid",
  "stained",
  "stale",
  "standard",
  "starchy",
  "stark",
  "starry",
  "steep",
  "sticky",
  "stiff",
  "stimulating",
  "stingy",
  "stormy",
  "straight",
  "strange",
  "steel",
  "strict",
  "strident",
  "striking",
  "striped",
  "strong",
  "studious",
  "stunning",
  "stupendous",
  "stupid",
  "sturdy",
  "stylish",
  "subdued",
  "submissive",
  "substantial",
  "subtle",
  "suburban",
  "sudden",
  "sugary",
  "sunny",
  "super",
  "superb",
  "superficial",
  "superior",
  "supportive",
  "surprised",
  "suspicious",
  "svelte",
  "sweaty",
  "sweet",
  "sweltering",
  "swift",
  "sympathetic",
  "tall",
  "talkative",
  "tame",
  "tan",
  "tangible",
  "tart",
  "tasty",
  "tattered",
  "taut",
  "tedious",
  "teeming",
  "tempting",
  "tender",
  "tense",
  "tepid",
  "terrible",
  "terrific",
  "testy",
  "thankful",
  "thick",
  "thin",
  "third",
  "thirsty",
  "this",
  "thorough",
  "thorny",
  "thoughtful",
  "threadbare",
  "thrifty",
  "thunderous",
  "tidy",
  "tight",
  "timely",
  "tinted",
  "tiny",
  "tired",
  "torn",
  "total",
  "tough",
  "traumatic",
  "treasured",
  "tremendous",
  "tragic",
  "trained",
  "tremendous",
  "triangular",
  "tricky",
  "trifling",
  "trim",
  "trivial",
  "troubled",
  "true",
  "trusting",
  "trustworthy",
  "trusty",
  "truthful",
  "tubby",
  "turbulent",
  "twin",
  "ugly",
  "ultimate",
  "unacceptable",
  "unaware",
  "uncomfortable",
  "uncommon",
  "unconscious",
  "understated",
  "unequaled",
  "uneven",
  "unfinished",
  "unfit",
  "unfolded",
  "unfortunate",
  "unhappy",
  "unhealthy",
  "uniform",
  "unimportant",
  "unique",
  "united",
  "unkempt",
  "unknown",
  "unlawful",
  "unlined",
  "unlucky",
  "unnatural",
  "unpleasant",
  "unrealistic",
  "unripe",
  "unruly",
  "unselfish",
  "unsightly",
  "unsteady",
  "unsung",
  "untidy",
  "untimely",
  "untried",
  "untrue",
  "unused",
  "unusual",
  "unwelcome",
  "unwieldy",
  "unwilling",
  "unwitting",
  "unwritten",
  "upbeat",
  "upright",
  "upset",
  "urban",
  "usable",
  "used",
  "useful",
  "utilized",
  "utter",
  "vacant",
  "vague",
  "vain",
  "valid",
  "valuable",
  "vapid",
  "variable",
  "vast",
  "velvety",
  "venerated",
  "vengeful",
  "verifiable",
  "vibrant",
  "vicious",
  "victorious",
  "vigilant",
  "vigorous",
  "villainous",
  "violet",
  "violent",
  "virtual",
  "virtuous",
  "visible",
  "vital",
  "vivacious",
  "vivid",
  "voluminous",
  "wan",
  "warlike",
  "warm",
  "warmhearted",
  "warped",
  "wary",
  "wasteful",
  "watchful",
  "waterlogged",
  "watery",
  "wavy",
  "wealthy",
  "weak",
  "weary",
  "webbed",
  "wee",
  "weekly",
  "weepy",
  "weighty",
  "weird",
  "welcome",
  "wet",
  "which",
  "whimsical",
  "whirlwind",
  "whispered",
  "white",
  "whole",
  "whopping",
  "wicked",
  "wide",
  "wiggly",
  "wild",
  "willing",
  "wilted",
  "winding",
  "windy",
  "winged",
  "wiry",
  "wise",
  "witty",
  "wobbly",
  "woeful",
  "wonderful",
  "wooden",
  "woozy",
  "wordy",
  "worldly",
  "worn",
  "worried",
  "worrisome",
  "worse",
  "worst",
  "worthless",
  "worthwhile",
  "worthy",
  "wrathful",
  "wretched",
  "writhing",
  "wrong",
  "wry",
  "yawning",
  "yearly",
  "yellow",
  "yellowish",
  "young",
  "youthful",
  "yummy",
  "zany",
  "zealous",
  "zesty",
  "zigzag"
];

export const animals = [
  "aardvark",
  "albatross",
  "alligator",
  "alpaca",
  "ant",
  "anteater",
  "antelope",
  "ape",
  "armadillo",
  "donkey",
  "baboon",
  "badger",
  "barracuda",
  "bat",
  "bear",
  "beaver",
  "bee",
  "bison",
  "boar",
  "buffalo",
  "butterfly",
  "camel",
  "capybara",
  "caribou",
  "cassowary",
  "cat",
  "caterpillar",
  "cattle",
  "chamois",
  "cheetah",
  "chicken",
  "chimpanzee",
  "chinchilla",
  "chough",
  "clam",
  "cobra",
  "cockroach",
  "cod",
  "corgi",
  "cormorant",
  "coyote",
  "crab",
  "crane",
  "crocodile",
  "crow",
  "curlew",
  "deer",
  "dinosaur",
  "dog",
  "dogfish",
  "dolphin",
  "dotterel",
  "dove",
  "dragonfly",
  "duck",
  "dugong",
  "dunlin",
  "eagle",
  "echidna",
  "eel",
  "eland",
  "elephant",
  "elk",
  "emu",
  "falcon",
  "ferret",
  "finch",
  "fish",
  "flamingo",
  "fly",
  "fox",
  "frog",
  "gaur",
  "gazelle",
  "gerbil",
  "giraffe",
  "gnat",
  "gnu",
  "goat",
  "goldfinch",
  "goldfish",
  "goose",
  "gorilla",
  "goshawk",
  "grasshopper",
  "grouse",
  "guanaco",
  "gull",
  "hamster",
  "hare",
  "hawk",
  "hedgehog",
  "heron",
  "herring",
  "hippopotamus",
  "hornet",
  "horse",
  "human",
  "hummingbird",
  "hyena",
  "ibex",
  "ibis",
  "jackal",
  "jaguar",
  "jay",
  "jellyfish",
  "kangaroo",
  "kingfisher",
  "koala",
  "kookabura",
  "kouprey",
  "kudu",
  "lapwing",
  "lark",
  "lemur",
  "leopard",
  "lion",
  "llama",
  "lobster",
  "locust",
  "loris",
  "louse",
  "lyrebird",
  "magpie",
  "mallard",
  "manatee",
  "mandrill",
  "mantis",
  "marten",
  "meerkat",
  "mink",
  "mole",
  "mongoose",
  "monkey",
  "moose",
  "mosquito",
  "mouse",
  "mule",
  "narwhal",
  "newt",
  "nightingale",
  "octopus",
  "okapi",
  "opossum",
  "oryx",
  "ostrich",
  "otter",
  "owl",
  "oyster",
  "panther",
  "parrot",
  "partridge",
  "peafowl",
  "pelican",
  "penguin",
  "pheasant",
  "pig",
  "pigeon",
  "pony",
  "porcupine",
  "porpoise",
  "quail",
  "quelea",
  "quetzal",
  "rabbit",
  "raccoon",
  "rail",
  "ram",
  "rat",
  "raven",
  "deer",
  "panda",
  "reindeer",
  "rhinoceros",
  "rook",
  "salamander",
  "salmon",
  "sandpiper",
  "sardine",
  "scorpion",
  "seahorse",
  "seal",
  "shark",
  "sheep",
  "shrew",
  "skunk",
  "slug",
  "snail",
  "snake",
  "sparrow",
  "spider",
  "spoonbill",
  "squid",
  "squirrel",
  "starling",
  "stingray",
  "stinkbug",
  "stork",
  "swallow",
  "swan",
  "tapir",
  "tarsier",
  "termite",
  "tiger",
  "toad",
  "trout",
  "turkey",
  "turtle",
  "viper",
  "vulture",
  "wallaby",
  "walrus",
  "wasp",
  "weasel",
  "whale",
  "wildcat",
  "wolf",
  "wolverine",
  "wombat",
  "woodpecker",
  "worm",
  "wren",
  "yak",
  "zebra"
];
