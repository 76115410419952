import urlJoin from "url-join";
import { httpPostSimple } from "./makeRequest.js";
/**
 * This function allows a user to log in with a one-click login process.
 * It sends the necessary data to the backend and handles the response.
 *
 * Never rejects. All information encoded in the resolved response.
 */
export async function requestOneClickLogin(zupassServerUrl, email, code, commitment, encryptionKey) {
    return httpPostSimple(urlJoin(zupassServerUrl, "/account/one-click-login"), async (resText) => ({
        value: JSON.parse(resText),
        success: true
    }), {
        email,
        code,
        commitment,
        encryptionKey
    });
}
