import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { QRDisplayWithRegenerateAndStorage, styled } from "@pcd/passport-ui";
import { useCallback } from "react";
import urlJoin from "url-join";
export const PODTicketPCDUI = {
    renderCardBody: PODTicketCardBody
};
function PODTicketCardBody({ pcd, idBasedVerifyURL }) {
    const ticketData = pcd.claim.ticket;
    return (_jsxs(Container, { children: [_jsx(TicketQR, { pcd: pcd, idBasedVerifyURL: idBasedVerifyURL }), _jsxs(TicketInfo, { children: [_jsx("span", { children: ticketData.attendeeName }), _jsx("span", { children: ticketData.attendeeEmail })] })] }));
}
function TicketQR({ pcd, idBasedVerifyURL }) {
    const generate = useCallback(async () => {
        console.log(linkToTicket(idBasedVerifyURL, pcd.claim.ticket.ticketId, pcd.claim.ticket.eventId));
        return linkToTicket(idBasedVerifyURL, pcd.claim.ticket.ticketId, pcd.claim.ticket.eventId);
    }, [idBasedVerifyURL, pcd.claim.ticket.eventId, pcd.claim.ticket.ticketId]);
    return (_jsx(QRDisplayWithRegenerateAndStorage, { generateQRPayload: generate, maxAgeMs: 1000 * 60, uniqueId: pcd.id }));
}
function makeIdBasedVerifyLink(baseUrl, ticketId) {
    return urlJoin(baseUrl, `?id=${ticketId}`);
}
export function linkToTicket(baseUrl, ticketId, eventId) {
    const encodedId = Buffer.from(JSON.stringify({
        ticketId: ticketId,
        eventId: eventId,
        timestamp: Date.now().toString()
    })).toString("base64");
    return makeIdBasedVerifyLink(baseUrl, encodedId);
}
const Container = styled.span `
  padding: 16px;
  overflow: hidden;
  width: 100%;
`;
const TicketInfo = styled.div `
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
