var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _ZupassAPIPODWrapper_api, _ZupassAPIWrapper_api;
import { POD } from "@pcd/pod";
class ZupassAPIPODWrapper {
    constructor(api) {
        _ZupassAPIPODWrapper_api.set(this, void 0);
        __classPrivateFieldSet(this, _ZupassAPIPODWrapper_api, api, "f");
    }
    async query(query) {
        const serialized = query.serialize();
        const pods = await __classPrivateFieldGet(this, _ZupassAPIPODWrapper_api, "f").pod.query(serialized);
        return pods.map((pod) => POD.deserialize(pod));
    }
    async insert(pod) {
        const serialized = pod.serialize();
        return __classPrivateFieldGet(this, _ZupassAPIPODWrapper_api, "f").pod.insert(serialized);
    }
    async delete(signature) {
        return __classPrivateFieldGet(this, _ZupassAPIPODWrapper_api, "f").pod.delete(signature);
    }
}
_ZupassAPIPODWrapper_api = new WeakMap();
/**
 * Wraps the internal Zupass API to provide a more user-friendly interface.
 * Specifically, this handles serialization and deserialization of PODs and
 * query data.
 */
export class ZupassAPIWrapper {
    // Feeds API is deliberately omitted
    constructor(api) {
        _ZupassAPIWrapper_api.set(this, void 0);
        __classPrivateFieldSet(this, _ZupassAPIWrapper_api, api, "f");
        this.pod = new ZupassAPIPODWrapper(api);
        this.identity = api.identity;
        this.gpc = api.gpc;
        this.fs = api.fs;
    }
}
_ZupassAPIWrapper_api = new WeakMap();
