export const ZKEdDSAEventTicketPCDTypeName = "zk-eddsa-event-ticket-pcd";
/**
 * Max supported size of validEventIds field in ZKEdDSAEventTicketPCDArgs.
 */
export const VALID_EVENT_IDS_MAX_LEN = 20;
/**
 * ZKEdDSAEventTicketPCD PCD type representation.
 */
export class ZKEdDSAEventTicketPCD {
    constructor(id, claim, proof) {
        this.id = id;
        this.claim = claim;
        this.proof = proof;
        this.type = ZKEdDSAEventTicketPCDTypeName;
        this.id = id;
        this.claim = claim;
        this.proof = proof;
    }
}
