import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { QRDisplayWithRegenerateAndStorage, styled } from "@pcd/passport-ui";
import { useCallback } from "react";
import urlJoin from "url-join";
export const PODTicketPCDUI = {
    renderCardBody: PODTicketCardBody
};
function PODTicketCardBody({ pcd, idBasedVerifyURL }) {
    return (_jsx(PODTicketCardBodyImpl, { ticketData: pcd.claim.ticket, idBasedVerifyURL: idBasedVerifyURL }));
}
export function PODTicketCardBodyImpl({ ticketData, idBasedVerifyURL }) {
    const hasImage = ticketData.imageUrl !== undefined;
    return (_jsxs(Container, { children: [hasImage && (_jsxs(TicketInfo, { children: [_jsx(TicketImage, { hidePadding: false, ticketData: ticketData }), _jsx("span", { children: ticketData?.attendeeName }), _jsx("span", { children: ticketData?.attendeeEmail })] })), !hasImage && (_jsxs(_Fragment, { children: [_jsx(TicketQR, { ticketData: ticketData, idBasedVerifyURL: idBasedVerifyURL }), _jsxs(TicketInfo, { children: [_jsx("span", { children: ticketData.attendeeName }), _jsx("span", { children: ticketData.attendeeEmail })] })] }))] }));
}
function TicketQR({ ticketData, idBasedVerifyURL }) {
    const generate = useCallback(async () => {
        if ([
            "53edb3e7-6733-41e0-a9be-488877c5c572", // eth berlin
            "508313ea-f16b-4729-bdf0-281c64493ca9", //  eth prague
            "5074edf5-f079-4099-b036-22223c0c6995" // devcon 7
        ].includes(ticketData.eventId) &&
            ticketData.ticketSecret) {
            return ticketData.ticketSecret;
        }
        return linkToTicket(idBasedVerifyURL, ticketData.ticketId, ticketData.eventId);
    }, [
        idBasedVerifyURL,
        ticketData.eventId,
        ticketData.ticketId,
        ticketData.ticketSecret
    ]);
    return (_jsx(QRDisplayWithRegenerateAndStorage, { generateQRPayload: generate, maxAgeMs: 1000 * 60, uniqueId: ticketData.ticketId }));
}
function makeIdBasedVerifyLink(baseUrl, ticketId) {
    return urlJoin(baseUrl, `?id=${ticketId}`);
}
export function linkToTicket(baseUrl, ticketId, eventId) {
    const encodedId = Buffer.from(JSON.stringify({
        ticketId: ticketId,
        eventId: eventId,
        timestamp: Date.now().toString()
    })).toString("base64");
    return makeIdBasedVerifyLink(baseUrl, encodedId);
}
const Container = styled.span `
  padding: 16px;
  overflow: hidden;
  width: 100%;
`;
const TicketInfo = styled.div `
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
function TicketImage({ ticketData, hidePadding }) {
    const { imageUrl, imageAltText } = ticketData;
    if (hidePadding)
        return _jsx("img", { src: imageUrl, alt: imageAltText });
    return (_jsx("div", { style: { padding: "8px" }, children: _jsx("img", { src: imageUrl, alt: imageAltText }) }));
}
