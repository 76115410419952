import NodeRSA from "node-rsa";
export function getPublicKey(pcd) {
    const encodedPublicKey = pcd?.proof?.rsaPCD?.proof?.publicKey;
    if (!encodedPublicKey) {
        return undefined;
    }
    try {
        const key = new NodeRSA(encodedPublicKey, "public");
        return key;
    }
    catch (e) {
        console.log("failed to deserialize key");
    }
    return undefined;
}
