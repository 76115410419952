import { PodspecError } from "./error";
import { isValid } from "./parse";
/**
 * The types of Podspec values that can be present in an "entries" Podspec.
 */
export var PodspecDataType;
(function (PodspecDataType) {
    PodspecDataType["String"] = "string";
    PodspecDataType["Int"] = "int";
    PodspecDataType["Cryptographic"] = "cryptographic";
    PodspecDataType["EdDSAPubKey"] = "eddsa_pubkey";
    PodspecDataType["Optional"] = "optional";
})(PodspecDataType || (PodspecDataType = {}));
/**
 * Base class for all Podspec values.
 */
export class PodspecValue {
    constructor(def) {
        this.def = def;
    }
    parse(data, params) {
        const result = this.safeParse(data, params);
        if (isValid(result)) {
            return result.value;
        }
        throw new PodspecError(result.issues);
    }
    safeParse(data, params) {
        return this._parse(data, params);
    }
}
