{
    "protocol": "groth16",
    "curve": "bn128",
    "nPublic": 18,
    "vk_alpha_1": [
        "20491192805390485299153009773594534940189261866228447918068658471970481763042",
        "9383485363053290200918347156157836566562967994039712273449902621266178545958",
        "1"
    ],
    "vk_beta_2": [
        [
            "6375614351688725206403948262868962793625744043794305715222011528459656738731",
            "4252822878758300859123897981450591353533073413197771768651442665752259397132"
        ],
        [
            "10505242626370262277552901082094356697409835680220590971873171140371331206856",
            "21847035105528745403288232691147584728191162732299865338377159692350059136679"
        ],
        [
            "1",
            "0"
        ]
    ],
    "vk_gamma_2": [
        [
            "10857046999023057135944570762232829481370756359578518086990519993285655852781",
            "11559732032986387107991004021392285783925812861821192530917403151452391805634"
        ],
        [
            "8495653923123431417604973247489272438418190587263600148770280649306958101930",
            "4082367875863433681332203403145435568316851327593401208105741076214120093531"
        ],
        [
            "1",
            "0"
        ]
    ],
    "vk_delta_2": [
        [
            "18159938933522841782521453139027788274544359011684857321429283082319603349141",
            "10013061990018256138188997547150036898941269801129660200548305161952624496867"
        ],
        [
            "7933680974523186976953904510321988677171110808445223314272373553539794815522",
            "895989131886858183855569919295182009834946956595306603508908917735338114148"
        ],
        [
            "1",
            "0"
        ]
    ],
    "vk_alphabeta_12": [
        [
            [
                "2029413683389138792403550203267699914886160938906632433982220835551125967885",
                "21072700047562757817161031222997517981543347628379360635925549008442030252106"
            ],
            [
                "5940354580057074848093997050200682056184807770593307860589430076672439820312",
                "12156638873931618554171829126792193045421052652279363021382169897324752428276"
            ],
            [
                "7898200236362823042373859371574133993780991612861777490112507062703164551277",
                "7074218545237549455313236346927434013100842096812539264420499035217050630853"
            ]
        ],
        [
            [
                "7077479683546002997211712695946002074877511277312570035766170199895071832130",
                "10093483419865920389913245021038182291233451549023025229112148274109565435465"
            ],
            [
                "4595479056700221319381530156280926371456704509942304414423590385166031118820",
                "19831328484489333784475432780421641293929726139240675179672856274388269393268"
            ],
            [
                "11934129596455521040620786944827826205713621633706285934057045369193958244500",
                "8037395052364110730298837004334506829870972346962140206007064471173334027475"
            ]
        ]
    ],
    "IC": [
        [
            "6438180349185149504792617042957432526651973430255393717721368797378891146406",
            "19226130128478156232981010411654306113613511708859110544279907932833395764190",
            "1"
        ],
        [
            "19925395793615359474457793107177165319543446437505252701342881929528703477828",
            "4224699309004394126159329648573625478951655304181255883879766675638602096010",
            "1"
        ],
        [
            "6357536989629025679564630993242188070059702676832615255619829743432884366607",
            "1264833692479982402644962763008658533944738360450079418245441759577479222983",
            "1"
        ],
        [
            "978180447398271836487265979096182719202724339247232473252813744555047006388",
            "20238061461458478528122302092756594595535158743047424350812968645998788085502",
            "1"
        ],
        [
            "18116882973450335109317805821287149166540312530413092476268591320098192105656",
            "3193428868707152263255765135401441684045485201417377757705455929879831485869",
            "1"
        ],
        [
            "15390126650334231780375820066960873152327099264629928753354776554497140985240",
            "1552864423699847575742895955276291980573994060845814134858382222808093024413",
            "1"
        ],
        [
            "8671190527029006110872205816285107877925197471426733064079619441588618118430",
            "13530453496160476931623138093857771152067590922940278933789352795449326408042",
            "1"
        ],
        [
            "4194484439780396552585430568901300825916142395745147493033316900343613954539",
            "3865298692475117320132916653697886289952447740811587550279897176902561084161",
            "1"
        ],
        [
            "12071732136894996378046421689336297634921877543887208956351364481985979454952",
            "9710996679035367692553631376181438298090523790207493329333646663003394027108",
            "1"
        ],
        [
            "17124610762292575785284298216708587880793490595854402999560975236558724993573",
            "7168539399794210894139971762674848937219131010862757113744202074007775095476",
            "1"
        ],
        [
            "13069126950518899421153380448029349694647018713852558699645475176661019046126",
            "7810756707850521942674891569438910090854676944739246014047712270184284700025",
            "1"
        ],
        [
            "17295666725366373439756405208089147891666881203090138445605272608188715057436",
            "14921730931406698210866434983648330051575333436156412555510088321699695548126",
            "1"
        ],
        [
            "17898217690230265286059009635614844995443521849790290345034696384144333166570",
            "16070071157148491739447887279272512950688573702659675408472533807450012706217",
            "1"
        ],
        [
            "17256708774312004977914662016297581963508352069607900146677719646834212759096",
            "744177756170180720827859828350882014041589400306413842195752172825972976142",
            "1"
        ],
        [
            "8768863451140146160469771533879939089908075925775574728013797634253639574600",
            "18082368166743565049952174697782139831921883339853141593603884568234000439217",
            "1"
        ],
        [
            "8338827843256106467811054863473798907272549759023084106321759164499950187914",
            "9632007668729369512245234946429556110129820676849688531854734702140432813602",
            "1"
        ],
        [
            "10285354574983059078966230256756237613123157675331090413353545884872485139578",
            "2820314157190050369052610961336419510893614319490344859275245275293863116212",
            "1"
        ],
        [
            "18328072001193424153759642605246851645533082117777453801044432526135262533530",
            "12300487065284514593209511760055683117852440374972790124281827526896640462938",
            "1"
        ],
        [
            "13723388537027247567340267334458743525456817733987503101149109786076035539089",
            "15763766250865249656500186835001901181444019871721891517797856363976413749613",
            "1"
        ]
    ]
}
