import { requireDefinedParameter } from "@pcd/util";
import { v4 as uuid } from "uuid";
import { ObjPCD, ObjPCDTypeName } from "./ObjPCD.js";
export async function prove(args) {
    const id = typeof args.id.value === "string" ? args.id.value : uuid();
    return new ObjPCD(id, {}, { obj: args.obj.value });
}
export async function verify(_pcd) {
    return true;
}
export async function serialize(pcd) {
    return {
        type: ObjPCDTypeName,
        pcd: JSON.stringify(pcd)
    };
}
export async function deserialize(serialized) {
    const { id, claim, proof } = JSON.parse(serialized);
    requireDefinedParameter(id, "id");
    requireDefinedParameter(claim, "claim");
    requireDefinedParameter(proof, "proof");
    return new ObjPCD(id, claim, proof);
}
export function getDisplayOptions(pcd) {
    return {
        header: "EdDSA Signature",
        displayName: "eddsa-sig-" + pcd.id.substring(0, 4)
    };
}
export const ObjPCDPackage = {
    name: ObjPCDTypeName,
    getDisplayOptions,
    prove,
    verify,
    serialize,
    deserialize
};
