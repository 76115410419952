import { PodspecDataType, PodspecValue } from "../base";
import { SUCCESS } from "../parse";
/**
 * A Podspec type for an optional value.
 *
 * Optional values represent entries which may be missing from a POD without
 * invalidating the POD. {@link PODEntries} does not support a concept of
 * optional or undefined entries, so if no value is provided for an optional
 * entry then it will be entirely omitted from the output.
 */
export class PodspecOptional extends PodspecValue {
    constructor(innerType) {
        super({
            type: PodspecDataType.Optional,
            innerType: innerType
        });
        this.innerType = innerType;
    }
    /**
     * Parses the given data into an optional value. Parsing an undefined value
     * is always successful, but if data is present then parsing is delegated to
     * the inner type.
     *
     * @param data - The data to parse.
     * @returns The parsed optional value, or an issue if the data is invalid.
     */
    _parse(data) {
        if (data === undefined) {
            return SUCCESS(undefined);
        }
        return this.def.innerType._parse(data);
    }
    /**
     * Serializes the optional Podspec to a cloneable object.
     *
     * @returns The serialized optional Podspec
     */
    serialize() {
        return {
            type: PodspecDataType.Optional,
            innerTypeDef: this.innerType.def
        };
    }
    /**
     * Creates a new optional Podspec.
     *
     * @param innerType - The inner type of the optional Podspec.
     * @returns The new optional Podspec.
     */
    static create(innerType) {
        return new PodspecOptional(innerType);
    }
}
