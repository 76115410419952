export var IssueCode;
(function (IssueCode) {
    IssueCode["invalid_type"] = "invalid_type";
    IssueCode["not_in_list"] = "not_in_list";
    IssueCode["excluded_by_list"] = "excluded_by_list";
    IssueCode["not_in_range"] = "not_in_range";
    IssueCode["missing_entry"] = "missing_entry";
    IssueCode["invalid_entry_name"] = "invalid_entry_name";
    IssueCode["invalid_tuple_entry"] = "invalid_tuple_entry";
    IssueCode["not_in_tuple_list"] = "not_in_tuple_list";
    IssueCode["excluded_by_tuple_list"] = "excluded_by_tuple_list";
    IssueCode["incorrect_signer"] = "incorrect_signer";
    IssueCode["incorrect_signature"] = "incorrect_signature";
    IssueCode["invalid_pod_value"] = "invalid_pod_value";
})(IssueCode || (IssueCode = {}));
export class PodspecError extends Error {
    get errors() {
        return this.issues;
    }
    constructor(issues) {
        super();
        this.issues = [];
        this.name = "PodspecError";
        this.issues = issues;
    }
}
