export var PCDRequestType;
(function (PCDRequestType) {
    PCDRequestType["Get"] = "Get";
    PCDRequestType["GetWithoutProving"] = "GetWithoutProving";
    PCDRequestType["Add"] = "Add";
    PCDRequestType["ProveAndAdd"] = "ProveAndAdd";
})(PCDRequestType || (PCDRequestType = {}));
export function getWithoutProvingUrl(zupassClientUrl, returnUrl, pcdType, postMessage = false) {
    const req = {
        type: PCDRequestType.GetWithoutProving,
        pcdType,
        returnUrl,
        postMessage
    };
    const encReq = encodeURIComponent(JSON.stringify(req));
    return `${zupassClientUrl}#/get-without-proving?request=${encReq}`;
}
export function constructZupassPcdGetRequestUrl(zupassClientUrl, returnUrl, pcdType, args, options, postMessage = false) {
    const req = {
        type: PCDRequestType.Get,
        returnUrl: returnUrl,
        args: args,
        pcdType,
        options,
        postMessage
    };
    const encReq = encodeURIComponent(JSON.stringify(req));
    return `${zupassClientUrl}#/prove?request=${encReq}`;
}
export function constructZupassPcdAddRequestUrl(zupassClientUrl, returnUrl, pcd, folder, postMessage = false, redirectToFolder) {
    const req = {
        type: PCDRequestType.Add,
        returnUrl,
        pcd,
        folder,
        postMessage,
        redirectToFolder
    };
    const eqReq = encodeURIComponent(JSON.stringify(req));
    return `${zupassClientUrl}#/add?request=${eqReq}`;
}
export function constructZupassPcdMintRequestUrl(zupassClientUrl, mintUrl, returnUrl, pcd, folder, postMessage = false, redirectToFolder) {
    const req = {
        type: PCDRequestType.Add,
        mintUrl,
        returnUrl,
        pcd,
        folder,
        postMessage,
        redirectToFolder
    };
    const eqReq = encodeURIComponent(JSON.stringify(req));
    return `${zupassClientUrl}#/add?request=${eqReq}`;
}
export function constructZupassPcdProveAndAddRequestUrl(zupassClientUrl, returnUrl, pcdType, args, options, returnPCD, folder, postMessage = false) {
    const req = {
        type: PCDRequestType.ProveAndAdd,
        returnUrl: returnUrl,
        pcdType,
        args,
        options,
        returnPCD,
        folder,
        postMessage
    };
    const eqReq = encodeURIComponent(JSON.stringify(req));
    return `${zupassClientUrl}#/add?request=${eqReq}`;
}
export function postSerializedPCDMessage(window, serialized) {
    window.postMessage({ encodedPCD: JSON.stringify(serialized) }, "*");
}
export function postSerializedMultiPCDMessage(window, pcds) {
    window.postMessage({ multiplePCDs: JSON.stringify(pcds) }, "*");
}
export function postPendingPCDMessage(window, pending) {
    window.postMessage({ encodedPendingPCD: JSON.stringify(pending) }, "*");
}
export var PayloadType;
(function (PayloadType) {
    PayloadType["RedirectTopicData"] = "topic-data";
    PayloadType["NullifierHash"] = "nullifier-hash";
    PayloadType["AnonTopicDataPayload"] = "anon-topic-data-payload";
    PayloadType["ReactData"] = "react-data";
})(PayloadType || (PayloadType = {}));
