import { sha256 } from "js-sha256";
export function getMessageWatermark(message) {
    const hashed = sha256(message).substring(0, 16);
    return BigInt("0x" + hashed);
}
export function encodeAnonMessageIdAndReaction(anonMessageId, reaction) {
    return `REACT:${anonMessageId}:${reaction}`;
}
export const getAnonTopicNullifier = () => {
    return BigInt("0x" + sha256("anon_message").substring(0, 16));
};
