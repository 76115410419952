/**
 * The globally unique type name of the {@link GPCPCD}.
 */
export const GPCPCDTypeName = "gpc-pcd";
/**
 * The GPC PCD is a generic proof of a configurable set of constraints about
 * one or more POD objects.  The {@link GPCPCDClaim} specifies the proof
 * configuration, and a redacted view of the proof inputs.  The
 * {@link GPCPCDProof} contains a cryptographic proof of the validity of
 * this proof.
 *
 * The proofs supported by this PCD are not tied to a single ZK circuit, but
 * can be generated using one of several circuits in a supported family,
 * depending on the needs of the configuration and inputs.  Picking the right
 * circuit and formatting the inputs is handled automatically using the
 * `@pcd/gpc` package.
 *
 * Verifying a GPCPCD indicates that the proof config and revealed inputs
 * match those used to generate the proof.  Since GPCs are very configurable,
 * the caller must separately check whether the proof config and revealed
 * values properly reflect the needs of their use case.  E.g. they should
 * check that the PODs were signed by a trusted public key, and that the
 * config matches their expectations.
 */
export class GPCPCD {
    /**
     * Create a PCD to encapsulate the given ID and GPC info.
     */
    constructor(id, claim, proof) {
        this.type = GPCPCDTypeName;
        this.id = id;
        this.claim = claim;
        this.proof = proof;
    }
}
