export const FAILURE = (issues) => ({
    isValid: false,
    issues
});
export const SUCCESS = (value) => ({
    isValid: true,
    value
});
export var ParsedType;
(function (ParsedType) {
    ParsedType["string"] = "string";
    ParsedType["number"] = "number";
    ParsedType["boolean"] = "boolean";
    ParsedType["array"] = "array";
    ParsedType["object"] = "object";
    ParsedType["null"] = "null";
    ParsedType["undefined"] = "undefined";
    ParsedType["PODStringValue"] = "PODStringValue";
    ParsedType["PODIntValue"] = "PODIntValue";
    ParsedType["PODCryptographicValue"] = "PODCryptographicValue";
    ParsedType["PODEdDSAPubKeyValue"] = "PODEdDSAPubKeyValue";
    ParsedType["BadPODValue"] = "BadPODValue";
    ParsedType["unknown"] = "unknown";
})(ParsedType || (ParsedType = {}));
export function isValid(result) {
    return result.isValid;
}
export class ParseStatus {
    constructor() {
        this.value = "valid";
    }
}
