/**
 * The global unique type name of the {@link ZKEdDSAFrogPCD}.
 */
export const ZKEdDSAFrogPCDTypeName = "zk-eddsa-frog-pcd";
/**
 * The ZK EdDSA Frog PCD enables the verification that an owner with a Semaphore
 * identity owns the EdDSA Frog PCD while keeping the owner's semaphore identity private.
 */
export class ZKEdDSAFrogPCD {
    constructor(id, claim, proof) {
        this.id = id;
        this.claim = claim;
        this.proof = proof;
        this.type = ZKEdDSAFrogPCDTypeName;
        this.id = id;
        this.claim = claim;
        this.proof = proof;
    }
}
