import { getErrorMessage } from "@pcd/util";
import { z } from "zod";
/**
 * Each new {@link Pipeline} type needs a corresponding entry in thie enum.
 */
export var PipelineType;
(function (PipelineType) {
    PipelineType["Lemonade"] = "Lemonade";
    PipelineType["Pretix"] = "Pretix";
    PipelineType["CSV"] = "CSV";
    PipelineType["POD"] = "POD";
})(PipelineType || (PipelineType = {}));
export var IncidentPolicy;
(function (IncidentPolicy) {
    IncidentPolicy["Everyone"] = "Everyone";
    IncidentPolicy["JustIvan"] = "JustIvan";
    IncidentPolicy["JustRichard"] = "JustRichard";
})(IncidentPolicy || (IncidentPolicy = {}));
const BasePipelineDefinitionSchema = z.object({
    id: z.string().uuid(),
    ownerUserId: z.string().uuid(),
    editorUserIds: z.array(z.string().uuid()),
    timeCreated: z.string(),
    timeUpdated: z.string()
});
const AlertsOptionsSchema = z.object({
    pagerduty: z.boolean().optional(),
    loadIncidentPagePolicy: z.nativeEnum(IncidentPolicy).optional(),
    discordTags: z.array(z.string()).optional(),
    discordAlerts: z.boolean().optional(),
    alertOnLogErrors: z.boolean().optional(),
    errorLogIgnoreRegexes: z.array(z.string()).optional(),
    alertOnLogWarnings: z.boolean().optional(),
    warningLogIgnoreRegexes: z.array(z.string()).optional(),
    alertOnAtomMismatch: z.boolean().optional()
});
const BasePipelineOptionsSchema = z.object({
    /**
     * Paused pipelines don't load data, but their APIs are still
     * accessible and enabled.
     */
    paused: z.boolean().optional(),
    name: z.string().optional(),
    notes: z.string().optional(),
    alerts: AlertsOptionsSchema.optional(),
    /**
     * Protected pipelines can't be deleted.
     */
    protected: z.boolean().optional(),
    important: z.boolean().optional()
});
/**
 * Pipeline definitions can also include manually-added tickets. Pipelines that
 * support this will create tickets according to these specifications, in
 * addition to those loaded from their primary data source.
 */
const ManualTicketSchema = z.object({
    /**
     * The ID of the ticket.
     */
    id: z.string().uuid(),
    /**
     * The generic issuance UUID of the event that the ticket is for.
     */
    eventId: z.string().uuid(),
    /**
     * The generic issuance UUID for the product/ticket type.
     */
    productId: z.string().uuid(),
    /**
     * The email to assign the ticket to.
     */
    attendeeEmail: z.string().email(),
    /**
     * The full name of the attendee.
     */
    attendeeName: z.string().min(1),
    timeCreated: z.string().optional()
});
const ManualTicketListSchema = z
    .array(ManualTicketSchema)
    .optional()
    .refine((manualTickets) => 
// If manualTickets is undefined then that's OK
manualTickets === undefined ||
    // Otherwise make sure each one has a unique ID
    manualTickets.length ===
        new Set(manualTickets.map((manualTicket) => manualTicket.id)).size, { message: "Ticket IDs must be unique" });
const LemonadePipelineTicketTypeConfigSchema = z.object({
    /**
     * The ID of this ticket type on the Lemonade end.
     */
    externalId: z.string(),
    /**
     * The UUID of this ticket type used in {@link EdDSATicketPCD}.
     */
    genericIssuanceProductId: z.string().uuid(),
    /**
     * Whether this ticket type is allowed to check other tickets in or not.
     */
    isSuperUser: z.boolean(),
    /**
     * Display name
     */
    name: z.string()
});
export const MemberCriteriaSchema = z.object({
    /**
     * generic issuance event id
     */
    eventId: z.string().uuid(),
    /**
     * generic issuance product id
     */
    productId: z.string().uuid().optional()
});
const SemaphoreGroupConfigSchema = z.object({
    /**
     * Defines the set of event ID/product ID pairs that qualify a ticket-holder
     * for membership in this group. If no product ID is specified, then all
     * tickets for the event will qualify for group membership.
     *
     * The groupId is a UUID which the administrator should generate.
     */
    groupId: z.string().uuid(),
    name: z.string().min(1),
    memberCriteria: z.array(MemberCriteriaSchema)
});
const SemaphoreGroupListSchema = z
    .array(SemaphoreGroupConfigSchema)
    .optional()
    .refine((groups) => 
// Groups being undefined is valid
groups === undefined ||
    // If groups are defined, the number of unique IDs must equal the
    // number of groups
    groups.length === new Set(groups.map((group) => group.groupId)).size, { message: "Semaphore group IDs must be unique" })
    .refine((groups) => 
// Groups being undefined is valid
groups === undefined ||
    // If groups are defined, the number of unique names must equal the
    // number of groups
    groups.length === new Set(groups.map((group) => group.name)).size, { message: "Semaphore group names must be unique" });
const LemonadePipelineEventConfigSchema = z.object({
    /**
     * The ID of this event on the Lemonade end.
     */
    externalId: z.string(),
    /**
     * Display name.
     */
    name: z.string(),
    /**
     * The UUID of this event used for {@link EdDSATicketPCD}.
     */
    genericIssuanceEventId: z.string().uuid(),
    /**
     * Roughly translates to Products in {@link EdDSATicketPCD}.
     */
    ticketTypes: z.array(LemonadePipelineTicketTypeConfigSchema)
});
export const ActionScreenConfigSchema = z.object({
    eventBannerUrl: z.string().optional(),
    eventNameConfig: z.string().optional()
});
export const BadgeConfigSchema = z.object({
    id: z.string(),
    eventName: z.string(),
    productName: z.string().optional(),
    imageUrl: z.string(),
    givers: z.array(z.string()).optional(),
    grantOnCheckin: z.boolean().optional(),
    maxPerDay: z.number().optional()
});
export const BadgesConfigSchema = z.object({
    enabled: z.boolean().optional(),
    choices: z.array(BadgeConfigSchema).optional()
});
export const ContactsConfigSchema = z.object({
    enabled: z.boolean().optional()
});
/**
 * Configuration of actions Podbox enables subscribers of the same Pipeline
 * to perform on each other:
 * - checking in
 * - issuing 'badges'
 * - pushing a contact card to scanee's zupass
 * - potentially other actions, like throwing snowballs.
 */
const TicketActionsOptionsSchema = z.object({
    badges: BadgesConfigSchema.optional(),
    contacts: ContactsConfigSchema.optional(),
    screenConfig: ActionScreenConfigSchema.optional()
});
const FeedIssuanceOptionsSchema = z.object({
    feedId: z.string(),
    feedDisplayName: z.string(),
    feedDescription: z.string(),
    feedFolder: z.string()
});
const ImageOptionsSchema = z.object({
    imageUrl: z.string(),
    requireCheckedIn: z.boolean()
});
const LemonadePipelineOptionsSchema = BasePipelineOptionsSchema.extend({
    /**
     * Configured by the user when setting up Lemonade as a data source.
     */
    oauthAudience: z.string(),
    oauthClientId: z.string(),
    oauthClientSecret: z.string(),
    oauthServerUrl: z.string(),
    backendUrl: z.string(),
    events: z.array(LemonadePipelineEventConfigSchema),
    superuserEmails: z.array(z.string()).optional(),
    feedOptions: FeedIssuanceOptionsSchema,
    manualTickets: ManualTicketListSchema,
    ticketActions: TicketActionsOptionsSchema.optional(),
    semaphoreGroups: SemaphoreGroupListSchema,
    enablePODTickets: z.boolean().optional()
}).refine((val) => {
    // Validate that the manual tickets have event and product IDs that match the
    // event configuration.
    const events = new Map(val.events.map((ev) => [ev.genericIssuanceEventId, ev]));
    for (const manualTicket of val.manualTickets ?? []) {
        // Check that the event exists
        const manualTicketEvent = events.get(manualTicket.eventId);
        if (!manualTicketEvent) {
            return false;
        }
        // Check that the event has a product with the product ID on the ticket
        if (!manualTicketEvent.ticketTypes.find((ticketType) => ticketType.genericIssuanceProductId === manualTicket.productId)) {
            return false;
        }
    }
    return true;
});
const LemonadePipelineDefinitionSchema = BasePipelineDefinitionSchema.extend({
    type: z.literal(PipelineType.Lemonade),
    options: LemonadePipelineOptionsSchema
});
export function isLemonadePipelineDefinition(d) {
    return d.type === PipelineType.Lemonade;
}
const PretixProductConfigSchema = z.object({
    /**
     * Pretix's item ID
     */
    externalId: z.string(),
    /**
     * Our UUID
     */
    genericIssuanceId: z.string().uuid(),
    /**
     * Display name
     */
    name: z.string(),
    /**
     * Is a user with this product a "superuser"?
     * Superusers are able to check tickets in to events.
     */
    isSuperUser: z.boolean(),
    /**
     * If the attendee's name is collected by a question
     * other than the default attendee name question, this
     * field lets you configure Podbox to prefer to read
     * names from answers to the question with this `question_identifier`
     *
     * see pretix docs here: https://docs.pretix.eu/en/latest/api/resources/orders.html#order-position-resource
     */
    nameQuestionPretixQuestionIdentitifier: z.string().optional()
});
const PretixEventConfigSchema = z.object({
    /**
     * Pretix's event ID
     */
    externalId: z.string(),
    /**
     * Our UUID
     */
    genericIssuanceId: z.string().uuid(),
    /**
     * Display name for the event
     */
    name: z.string(),
    /**
     * Options to configure displaying an image instead of the QR code
     */
    imageOptions: ImageOptionsSchema.optional(),
    products: z.array(PretixProductConfigSchema),
    /**
     * Skip validation of event settings - use with caution!
     */
    skipSettingsValidation: z.boolean().optional()
});
export const AutoIssuanceOptionsSchema = z.object({
    memberCriteria: z.array(MemberCriteriaSchema),
    eventId: z.string(),
    productId: z.string(),
    quantity: z.number(),
    schedule: z.object({
        startDate: z.string(),
        endDate: z.string().optional(),
        intervalMs: z.number()
    })
});
export const UserPermissionsOptionsSchema = z.object({
    members: z.array(MemberCriteriaSchema),
    canCheckIn: z.object({
        eventId: z.string(),
        productId: z.string().optional()
    })
});
const PretixPipelineOptionsSchema = BasePipelineOptionsSchema.extend({
    /**
     * This object represents a configuration from which the server can instantiate
     * a functioning {@link PretixPipeline}. Partially specified by the user.
     */
    pretixAPIKey: z.string(),
    pretixOrgUrl: z.string(),
    events: z.array(PretixEventConfigSchema),
    feedOptions: FeedIssuanceOptionsSchema,
    manualTickets: ManualTicketListSchema,
    semaphoreGroups: SemaphoreGroupListSchema,
    enablePODTickets: z.boolean().optional(),
    autoIssuance: z.array(AutoIssuanceOptionsSchema).optional(),
    userPermissions: z.array(UserPermissionsOptionsSchema).optional()
}).refine((val) => {
    // Validate that the manual tickets have event and product IDs that match the
    // event configuration.
    const events = new Map(val.events.map((ev) => [ev.genericIssuanceId, ev]));
    for (const manualTicket of val.manualTickets ?? []) {
        // Check that the event exists
        const manualTicketEvent = events.get(manualTicket.eventId);
        if (!manualTicketEvent) {
            return false;
        }
        // Check that the event has a product with the product ID on the ticket
        if (!manualTicketEvent.products.find((product) => product.genericIssuanceId === manualTicket.productId)) {
            return false;
        }
    }
    return true;
});
const PretixPipelineDefinitionSchema = BasePipelineDefinitionSchema.extend({
    type: z.literal(PipelineType.Pretix),
    options: PretixPipelineOptionsSchema
});
export function isPretixPipelineDefinition(d) {
    return d.type === PipelineType.Pretix;
}
export var CSVPipelineOutputType;
(function (CSVPipelineOutputType) {
    /**
     * {@link EdDSAMessagePCD}
     */
    CSVPipelineOutputType["Message"] = "EdDSAMessage";
    CSVPipelineOutputType["Ticket"] = "EdDSATicket";
    CSVPipelineOutputType["PODTicket"] = "PODTicketPCD";
})(CSVPipelineOutputType || (CSVPipelineOutputType = {}));
const CSVPipelineOptionsSchema = BasePipelineOptionsSchema.extend({
    csv: z.string(),
    outputType: z.nativeEnum(CSVPipelineOutputType).optional(),
    feedOptions: FeedIssuanceOptionsSchema,
    issueToUnmatchedEmail: z.boolean().optional(),
    semaphoreGroupName: z.string().optional()
});
const CSVPipelineDefinitionSchema = BasePipelineDefinitionSchema.extend({
    type: z.literal(PipelineType.CSV),
    options: CSVPipelineOptionsSchema
});
export function isCSVPipelineDefinition(d) {
    return d.type === PipelineType.CSV;
}
/**
 * POD Pipeline.
 */
export var PODPipelineInputType;
(function (PODPipelineInputType) {
    PODPipelineInputType["CSV"] = "CSV";
})(PODPipelineInputType || (PODPipelineInputType = {}));
export var PODPipelineInputFieldType;
(function (PODPipelineInputFieldType) {
    PODPipelineInputFieldType["String"] = "string";
    PODPipelineInputFieldType["Int"] = "int";
    PODPipelineInputFieldType["Date"] = "date";
    PODPipelineInputFieldType["Boolean"] = "boolean";
    PODPipelineInputFieldType["UUID"] = "uuid";
    PODPipelineInputFieldType["Cryptographic"] = "cryptographic";
    PODPipelineInputFieldType["EdDSAPubKey"] = "eddsa_pubkey";
})(PODPipelineInputFieldType || (PODPipelineInputFieldType = {}));
const PODPipelineInputFieldSchema = z.object({
    type: z.nativeEnum(PODPipelineInputFieldType)
});
const PODPipelineInputColumnsSchema = z.record(z.string(), PODPipelineInputFieldSchema);
const PODPipelineBaseInputSchema = z.object({
    type: z.nativeEnum(PODPipelineInputType),
    columns: PODPipelineInputColumnsSchema
});
const PODPipelineCSVInputSchema = PODPipelineBaseInputSchema.extend({
    type: z.literal(PODPipelineInputType.CSV),
    csv: z.string()
});
const PODPipelineInputSchema = z.discriminatedUnion("type", [
    PODPipelineCSVInputSchema
]);
export var PODPipelinePCDTypes;
(function (PODPipelinePCDTypes) {
    PODPipelinePCDTypes["PODPCD"] = "PODPCD";
    PODPipelinePCDTypes["PODTicketPCD"] = "PODTicketPCD";
})(PODPipelinePCDTypes || (PODPipelinePCDTypes = {}));
const PODPipelineSupportedPODValueTypes = z.enum([
    "string",
    "int",
    "cryptographic",
    "eddsa_pubkey"
]);
const PODPipelinePODEntrySchema = z.object({
    type: PODPipelineSupportedPODValueTypes,
    source: z.discriminatedUnion("type", [
        z.object({ type: z.literal("input"), name: z.string() }),
        z.object({ type: z.literal("credentialSemaphoreID") }),
        z.object({ type: z.literal("credentialEmail") }),
        z.object({
            type: z.literal("configured"),
            value: z.string()
        })
    ])
});
const PODPipelinePODEntriesSchema = z.record(z.string(), PODPipelinePODEntrySchema);
export const PODPipelineOutputMatchSchema = z.discriminatedUnion("type", [
    z.object({ type: z.literal("semaphoreID"), entry: z.string() }),
    z.object({ type: z.literal("email"), entry: z.string() }),
    z.object({ type: z.literal("none") })
]);
const PODPipelineOutputSchema = z.object({
    pcdType: z.nativeEnum(PODPipelinePCDTypes),
    /**
     * @todo verify that all input-derived entries have matching columns and
     * possibly that column types match entry types
     */
    entries: PODPipelinePODEntriesSchema,
    match: PODPipelineOutputMatchSchema
});
export function validatePODPipelineOptions(options) {
    for (const [outputName, output] of Object.entries(options.outputs)) {
        for (const entry of Object.values(output.entries)) {
            if (entry.source.type === "input") {
                if (!options.input.columns[entry.source.name]) {
                    throw new Error(`Output ${outputName} has an input column ${entry.source.name} that does not exist in the input`);
                }
            }
        }
    }
}
const PODPipelineFeedOptionsSchema = FeedIssuanceOptionsSchema.extend({
    feedType: z.enum(["deleteAndReplace", "replace"])
});
const PODPipelineOptionsSchema = BasePipelineOptionsSchema.extend({
    input: PODPipelineInputSchema,
    outputs: z.record(z.string(), PODPipelineOutputSchema),
    feedOptions: PODPipelineFeedOptionsSchema
}).superRefine((val, ctx) => {
    try {
        validatePODPipelineOptions(val);
    }
    catch (e) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: getErrorMessage(e)
        });
    }
});
export const PODPipelineDefinitionSchema = BasePipelineDefinitionSchema.extend({
    type: z.literal(PipelineType.POD),
    options: PODPipelineOptionsSchema
});
export function isPODPipelineDefinition(d) {
    return d.type === PipelineType.POD;
}
/**
 * This item is exported so that we can use it for validation on generic issuance server.
 */
export const PipelineDefinitionSchema = z.discriminatedUnion("type", [
    LemonadePipelineDefinitionSchema,
    PretixPipelineDefinitionSchema,
    CSVPipelineDefinitionSchema,
    PODPipelineDefinitionSchema
]);
const PipelineHistoryEntrySchema = z.object({
    id: z.string().uuid(),
    pipeline: PipelineDefinitionSchema,
    timeCreated: z.string(),
    editorUserId: z.string().optional()
});
