/**
 * The globally unique type name of the {@link PODTicketPCD}.
 */
export const PODTicketPCDTypeName = "pod-ticket-pcd";
/**
 * Copied from {@link TicketCategory} in {@link EdDSATicketPCD}.
 */
export var TicketCategory;
(function (TicketCategory) {
    TicketCategory[TicketCategory["ZuConnect"] = 0] = "ZuConnect";
    TicketCategory[TicketCategory["Devconnect"] = 1] = "Devconnect";
    TicketCategory[TicketCategory["PcdWorkingGroup"] = 2] = "PcdWorkingGroup";
    TicketCategory[TicketCategory["Zuzalu"] = 3] = "Zuzalu";
    TicketCategory[TicketCategory["Generic"] = 4] = "Generic";
})(TicketCategory || (TicketCategory = {}));
export class PODTicketPCD {
    /**
     * The PODTicketPCD consists of a claim about {@link IPODTicketData}, and a
     * standard {@link PODPCDProof}.
     */
    constructor(id, claim, proof) {
        this.type = PODTicketPCDTypeName;
        this.id = id;
        this.claim = claim;
        this.proof = proof;
    }
}
/**
 * Returns true if {@link pcd} is an {@link PODTicketPCD} or false otherwise.
 */
export function isPODTicketPCD(pcd) {
    return pcd.type === PODTicketPCDTypeName;
}
/**
 * Throws if {@link pcd} is not a {@link PODTicketPCD}
 */
export function expectIsPODTicketPCD(pcd) {
    if (!isPODTicketPCD(pcd)) {
        throw new Error("Expected PODTicketPCD");
    }
}
