import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FieldLabel, Spacer, TextContainer, styled } from "@pcd/passport-ui";
import Airtable from "airtable";
import { useEffect, useState } from "react";
export const HaLoNoncePCDUI = {
    renderCardBody: HaLoNonceCardBody,
    getHeader: Header
};
// Read-only API key into Airtable with no sensitive data
const base = new Airtable({
    apiKey: "pat5y56owllLzfmW4.18658c109003682514513254c6f464f52022562acbb3af33d7fd95f05eebb6f2"
}).base("appJcTn3eQUXKQEKT");
function useAirtableData(pubkeyHex) {
    const [loadedAirtable, setLoadedAirtable] = useState(false);
    const [imageUrl, setImageUrl] = useState(undefined);
    const [headerText, setHeaderText] = useState("NFC STAMP");
    useEffect(() => {
        if (loadedAirtable)
            return;
        base("Image link")
            .select({
            fields: ["pubKeyHex", "imageUrl", "experienceName"]
        })
            .eachPage(function page(records, fetchNextPage) {
            for (const record of records) {
                if (record.get("pubKeyHex") === pubkeyHex) {
                    const recordImageUrl = record.get("imageUrl");
                    if (recordImageUrl) {
                        setImageUrl(recordImageUrl.toString());
                    }
                    const experienceName = record.get("experienceName");
                    if (experienceName) {
                        setHeaderText(experienceName.toString().toUpperCase());
                    }
                    break;
                }
            }
            fetchNextPage();
        }, function done(err) {
            if (err) {
                console.error(err);
                return;
            }
            setLoadedAirtable(true);
        });
    }, [loadedAirtable, pubkeyHex]);
    return { headerText, imageUrl, loadedAirtable };
}
function Header({ pcd }) {
    const { headerText } = useAirtableData(pcd.claim.pubkeyHex);
    return _jsx(_Fragment, { children: headerText });
}
function HaLoNonceCardBody({ pcd }) {
    const { loadedAirtable, imageUrl } = useAirtableData(pcd.claim.pubkeyHex);
    if (!loadedAirtable) {
        return _jsx(Container, {});
    }
    else if (imageUrl === undefined) {
        return (_jsxs(Container, { children: [_jsx(FieldLabel, { children: "Unique nonce" }), _jsx(TextContainer, { children: parseInt(pcd.proof.signedDigest.substring(0, 8), 16) }), _jsx(Spacer, { h: 8 }), _jsx(FieldLabel, { children: "Card public key" }), _jsx(TextContainer, { children: pcd.claim.pubkeyHex }), _jsx(Spacer, { h: 8 })] }));
    }
    return (_jsxs(Container, { children: [_jsx("img", { draggable: "false", src: imageUrl }), _jsx(Spacer, { h: 8 }), _jsx("center", { children: _jsxs(FieldLabel, { children: ["Unique nonce: ", parseInt(pcd.proof.signedDigest.substring(0, 8), 16)] }) })] }));
}
const Container = styled.div `
  padding: 16px;
  overflow: hidden;
  width: 100%;
`;
