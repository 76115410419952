import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { EdDSATicketPCDPackage, TicketCategory, getEdDSATicketData } from "@pcd/eddsa-ticket-pcd";
import { ZUCONNECT_23_DAY_PASS_PRODUCT_ID } from "@pcd/passport-interface";
import { Spacer, ToggleSwitch, css, styled } from "@pcd/passport-ui";
import { useCallback, useState } from "react";
import { TicketQR } from "./TicketQR.js";
export const EdDSATicketPCDUI = {
    renderCardBody: EdDSATicketPCDCardBody,
    getHeader
};
function EdDSATicketPCDCardBody({ pcd, identityPCD, verifyURL, idBasedVerifyURL, hidePadding }) {
    const hasImage = pcd.claim.ticket.imageUrl !== undefined;
    const ticketData = getEdDSATicketData(pcd);
    const [zk, setZk] = useState(idBasedVerifyURL === undefined);
    const onToggle = useCallback(() => {
        setZk(!zk);
    }, [zk]);
    const redact = zk && idBasedVerifyURL !== undefined;
    return (_jsxs(Container, { padding: !hasImage, children: [hasImage && (_jsxs(TicketInfo, { children: [_jsx(TicketImage, { hidePadding: hidePadding, pcd: pcd }), _jsx("span", { children: ticketData?.attendeeName }), _jsx("span", { children: ticketData?.attendeeEmail })] })), !hasImage && (_jsxs(TicketInfo, { children: [_jsx(TicketQR, { pcd: pcd, identityPCD: identityPCD, verifyURL: verifyURL, idBasedVerifyURL: idBasedVerifyURL, zk: zk }), _jsx(Spacer, { h: 8 }), ticketData?.attendeeName && (_jsx(RedactedText, { redacted: redact, children: ticketData?.attendeeName })), _jsx(RedactedText, { redacted: redact, children: ticketData?.attendeeEmail }), false && (_jsx(ZKMode, { children: _jsx(ToggleSwitch, { label: "ZK mode", checked: zk, onChange: onToggle }) }))] }))] }));
}
function TicketImage({ pcd, hidePadding }) {
    const { imageUrl, imageAltText } = pcd.claim.ticket;
    if (hidePadding)
        return _jsx("img", { src: imageUrl, alt: imageAltText });
    return (_jsx("div", { style: { padding: "8px" }, children: _jsx("img", { src: imageUrl, alt: imageAltText }) }));
}
function getHeader({ pcd }) {
    let header;
    if (pcd.claim.ticket.ticketCategory === TicketCategory.ZuConnect &&
        pcd.claim.ticket.productId === ZUCONNECT_23_DAY_PASS_PRODUCT_ID) {
        header = "ZUCONNECT '23 DAY PASS";
    }
    else {
        header = EdDSATicketPCDPackage.getDisplayOptions?.(pcd).header ?? "";
    }
    return _jsx(Uppercase, { children: header });
}
const Container = styled.span `
  ${({ padding }) => padding
    ? css `
          padding: 16px;
        `
    : css ``}
  overflow: hidden;
  width: 100%;
`;
const TicketInfo = styled.div `
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const Uppercase = styled.span `
  text-transform: uppercase;
`;
const RedactedText = styled.div `
  ${({ redacted }) => redacted
    ? css `
          color: transparent;
          &:before {
            border-radius: 4px;
            background-color: var(--bg-dark-primary);
            color: var(--bg-dark-primary);
            content: "REDACTED";
            color: white;
            font-weight: bold;
            min-width: 100%;
            text-align: center;
            position: absolute;
            left: 0;
          }
        `
    : css ``}

  margin-bottom: 4px;
  padding: 2px;
  width: 300px;
  position: relative;
  text-align: center;
  transition-property: color, background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  /* Same duration as the toggle slide */
  transition-duration: 300ms;
`;
const ZKMode = styled.div `
  display: flex;
  text-align: right;
  margin-top: 8px;
  padding: 0px 16px;
  width: 100%;
  justify-content: flex-end;
`;
