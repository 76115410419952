import { toBigIntBE, toBufferBE } from "@trufflesuite/bigint-buffer";
import { MessageSchema } from "../Message.js";
export function stringifyMsg(message) {
    return JSON.stringify(message);
}
export function destringifyMsg(serializedMessage) {
    return JSON.parse(serializedMessage);
}
export function bigintifyMsg(msg) {
    const str = stringifyMsg(msg);
    const buf = Buffer.from(str, "utf-8");
    const int = toBigIntBE(buf);
    return { int, len: str.length };
}
export function parseBigintifiedMsg(int) {
    const buf = toBufferBE(int.int, int.len);
    const msgString = buf.toString("utf-8");
    try {
        const msg = MessageSchema.parse(JSON.parse(msgString));
        return msg;
    }
    catch (e) {
        console.warn(e);
        return MessageSchema.parse({});
    }
}
