import { getHash } from "@pcd/passport-crypto";
import { PCDCollection } from "@pcd/pcd-collection";
import stringify from "fast-json-stable-stringify";
import { NetworkFeedApi } from "./FeedAPI.js";
import { FeedSubscriptionManager } from "./SubscriptionManager.js";
export function isSyncedEncryptedStorageV1(
// eslint-disable-next-line @typescript-eslint/no-explicit-any
storage) {
    return storage._storage_version === undefined;
}
export function isSyncedEncryptedStorageV2(
// eslint-disable-next-line @typescript-eslint/no-explicit-any
storage) {
    return storage._storage_version === "v2";
}
export function isSyncedEncryptedStorageV3(
// eslint-disable-next-line @typescript-eslint/no-explicit-any
storage) {
    return storage._storage_version === "v3";
}
export function isSyncedEncryptedStorageV4(
// eslint-disable-next-line @typescript-eslint/no-explicit-any
storage) {
    return storage._storage_version === "v4";
}
/**
 * Deserialize a decrypted storage object and set up the PCDCollection and
 * FeedSubscriptionManager to manage its data.  If the storage comes from
 * an older format which doesn't include subscriptions, then the
 * FeedSubscriptionManager will be empty.
 */
export async function deserializeStorage(storage, pcdPackages) {
    let pcds;
    let subscriptions;
    if (isSyncedEncryptedStorageV4(storage)) {
        pcds = await PCDCollection.deserialize(pcdPackages, storage.pcds);
        subscriptions = FeedSubscriptionManager.deserialize(new NetworkFeedApi(), storage.subscriptions);
    }
    else if (isSyncedEncryptedStorageV3(storage)) {
        pcds = await PCDCollection.deserialize(pcdPackages, storage.pcds);
        subscriptions = FeedSubscriptionManager.deserialize(new NetworkFeedApi(), storage.subscriptions);
    }
    else if (isSyncedEncryptedStorageV2(storage)) {
        pcds = await PCDCollection.deserialize(pcdPackages, storage.pcds);
        subscriptions = new FeedSubscriptionManager(new NetworkFeedApi());
    }
    else if (isSyncedEncryptedStorageV1(storage)) {
        pcds = new PCDCollection(pcdPackages);
        await pcds.deserializeAllAndAdd(storage.pcds);
        subscriptions = new FeedSubscriptionManager(new NetworkFeedApi());
    }
    else {
        throw new Error(`Unknown SyncedEncryptedStorage version 
      ${storage["_storage_version"]}`);
    }
    return {
        pcds,
        subscriptions,
        storageHash: await getStorageHash(storage)
    };
}
/**
 * Serializes a user's PCDs and relates state for storage.  The result is
 * unencrypted, and always uses the latest format.  The hash uniquely identifies
 * the content, as described in getStorageHash.
 */
export async function serializeStorage(user, pcds, subscriptions) {
    const serializedStorage = {
        pcds: await pcds.serializeCollection(),
        self: user,
        subscriptions: subscriptions.serialize(),
        _storage_version: "v4"
    };
    return {
        serializedStorage: serializedStorage,
        storageHash: await getStorageHash(serializedStorage)
    };
}
/**
 * Calculates a hash to uniquely identify the given seralized storage.
 */
export async function getStorageHash(storage) {
    return await getHash(stringify(storage));
}
