import NodeRSA from "node-rsa";
export function getTicketData(pcd) {
    let ticketData = {};
    try {
        ticketData = JSON.parse(pcd?.proof?.rsaPCD?.claim?.message ?? "{}");
    }
    catch (e) {
        //
    }
    return ticketData;
}
export function getPublicKey(pcd) {
    const encodedPublicKey = pcd?.proof?.rsaPCD?.proof?.publicKey;
    if (!encodedPublicKey) {
        return undefined;
    }
    try {
        const key = new NodeRSA(encodedPublicKey, "public");
        return key;
    }
    catch (e) {
        console.log("failed to deserialize key");
    }
    return undefined;
}
const INVALID_TICKET_QR_CODE_COLOR = "#d3d3d3";
export function getQRCodeColorOverride(pcd) {
    const ticketData = getTicketData(pcd);
    if (ticketData.isConsumed || ticketData.isRevoked) {
        return INVALID_TICKET_QR_CODE_COLOR;
    }
    // otherwise, don't override and use default
    return undefined;
}
