{
    "protocol": "groth16",
    "curve": "bn128",
    "nPublic": 38,
    "vk_alpha_1": [
        "20491192805390485299153009773594534940189261866228447918068658471970481763042",
        "9383485363053290200918347156157836566562967994039712273449902621266178545958",
        "1"
    ],
    "vk_beta_2": [
        [
            "6375614351688725206403948262868962793625744043794305715222011528459656738731",
            "4252822878758300859123897981450591353533073413197771768651442665752259397132"
        ],
        [
            "10505242626370262277552901082094356697409835680220590971873171140371331206856",
            "21847035105528745403288232691147584728191162732299865338377159692350059136679"
        ],
        [
            "1",
            "0"
        ]
    ],
    "vk_gamma_2": [
        [
            "10857046999023057135944570762232829481370756359578518086990519993285655852781",
            "11559732032986387107991004021392285783925812861821192530917403151452391805634"
        ],
        [
            "8495653923123431417604973247489272438418190587263600148770280649306958101930",
            "4082367875863433681332203403145435568316851327593401208105741076214120093531"
        ],
        [
            "1",
            "0"
        ]
    ],
    "vk_delta_2": [
        [
            "1816911282723953521360374096804693609948256596921895265929104078200823204675",
            "4794378188555673810018158797263945613117081424700154854974240721894252090534"
        ],
        [
            "13543378357184474310383646423534605062703850124878450029441667582061275654866",
            "4822598240965235353021859310978490456254180072341966996061361969858340984511"
        ],
        [
            "1",
            "0"
        ]
    ],
    "vk_alphabeta_12": [
        [
            [
                "2029413683389138792403550203267699914886160938906632433982220835551125967885",
                "21072700047562757817161031222997517981543347628379360635925549008442030252106"
            ],
            [
                "5940354580057074848093997050200682056184807770593307860589430076672439820312",
                "12156638873931618554171829126792193045421052652279363021382169897324752428276"
            ],
            [
                "7898200236362823042373859371574133993780991612861777490112507062703164551277",
                "7074218545237549455313236346927434013100842096812539264420499035217050630853"
            ]
        ],
        [
            [
                "7077479683546002997211712695946002074877511277312570035766170199895071832130",
                "10093483419865920389913245021038182291233451549023025229112148274109565435465"
            ],
            [
                "4595479056700221319381530156280926371456704509942304414423590385166031118820",
                "19831328484489333784475432780421641293929726139240675179672856274388269393268"
            ],
            [
                "11934129596455521040620786944827826205713621633706285934057045369193958244500",
                "8037395052364110730298837004334506829870972346962140206007064471173334027475"
            ]
        ]
    ],
    "IC": [
        [
            "7039163794843290796256368468693852992261864980639380847782867461741038210431",
            "13828571545952070419695572439672637697093967550127663217094587479939756801713",
            "1"
        ],
        [
            "3958090907019850444580447271310783643067855398231992297257715727710216995446",
            "20221946439601599894288820734713434259239717191029254240067234373135565758177",
            "1"
        ],
        [
            "900186639711238933493055667378009920193627212372904879368486442415809327595",
            "2326167641766524616999631967433198170614424673993051767085816973791951172320",
            "1"
        ],
        [
            "5036413725381298640320115097177392324444247429122196014822193539177279161834",
            "16915948281029825623174724126850423768748230097781953657414920017958567938481",
            "1"
        ],
        [
            "18760100143371695362362583151699410223835931838504964976371030235483771799520",
            "11050897648840559830340797268632494985552806330900971650426635140540632129623",
            "1"
        ],
        [
            "14405103043934777929451041926853384737748587264397789238453021115804714011027",
            "17654525523246776275961068512159018488399387144246684730694339431289852689612",
            "1"
        ],
        [
            "8723869934697142623491762263289398094319535893464503540125898389370968107859",
            "6562444046746975238614247431088671155226534237756214900132774223548393484900",
            "1"
        ],
        [
            "14577478605943949020672432197678273024089978103276775373202577864795436168402",
            "20868380911669423225158693169242758989558229682271980505657366061586596203338",
            "1"
        ],
        [
            "15078791307200682406383940510187595016164044832563024269891293768166347461344",
            "13807879254500296471557402479543820453954075404741718297177665886866496451391",
            "1"
        ],
        [
            "11961110457054262187040141268827975035460766426109310097612340764580611314242",
            "648031620139716874034542002574123681367629070550974595278392168004036814626",
            "1"
        ],
        [
            "9897786420777014154834245148124872045575237833648028105961996898423566286793",
            "10942250463782575990311669310939232003635777350050348004971415243722694683862",
            "1"
        ],
        [
            "21768976691153943693253939674737520933075287952326155542834234684045105263955",
            "2652628038258207868440308689934020510765602358527332281459263595352308874872",
            "1"
        ],
        [
            "10579889892022441902715761343940775692321155123038188581132868576263856691960",
            "14197080288473739214766468387110821163678798975745451452929084680507366969089",
            "1"
        ],
        [
            "17381487274016777148244396779385401991045642828052327241661444508026488993960",
            "12631141756649305162072161190046426727112068887466313087474366448379889938290",
            "1"
        ],
        [
            "13935047382751423896533075574654791455853724928466459591893970338304052339429",
            "6824865220976543574218366346391934951925243253294023634161017592510424936549",
            "1"
        ],
        [
            "7031992312358334117229960826366500136698824958913380375057168422867887208482",
            "6487726177217344454795293919275011847002886774229625835362883818222058658917",
            "1"
        ],
        [
            "32761952607172566377921792852655350243312728025797797731884919650955995978",
            "17109740037766941001038815791052639848028856032033398873318266457482577886649",
            "1"
        ],
        [
            "5148130823680965556573321200326358804854949261914205931196224467597274599399",
            "17786165933748885174698871854113633988020047930367652317579732342918892135076",
            "1"
        ],
        [
            "19132395236354116173686960242674593409872273373618210170105548787911478039676",
            "13128673728382375315191668017332103847318829241457370626993176402741448018866",
            "1"
        ],
        [
            "14279232715058070388045405059532116192488308995813346048366203712476135182708",
            "15194615736824271563039224473810596003691641177247333143890653748759024086797",
            "1"
        ],
        [
            "9955090722504979957069720304999125823978111318362496584519854575527608185162",
            "5794103785028496675031047406750626512072617762810766655823567669958439141907",
            "1"
        ],
        [
            "3093386023754979021969916793626732114241059635051234406414231194529079272032",
            "2726333648975816401517500089384058227785233536677037001841489035806732587931",
            "1"
        ],
        [
            "5277410462435782523915882980275775886349488617157850699431034750288036800613",
            "21607346138964363953763925149731352915511002970774217667749452112345555034956",
            "1"
        ],
        [
            "2882073216919257197946498011741429525374768355767062401579097340303609014667",
            "13336208254651518889575781043861573326120722149864211966571295065261003981732",
            "1"
        ],
        [
            "16518085772523452403713249212239346119989769943791821955471370367814804849274",
            "3163851008551205343892721959924291514513839424028748364301581737083684712635",
            "1"
        ],
        [
            "21443140829801323335830440272589422531303604169183393653690045415169893110317",
            "11843677807581613645245376500039550313868511109982120780557566436801551936632",
            "1"
        ],
        [
            "9011343512724109228637988929452301928814416148302399365691495043540007452711",
            "19203719374228430540624285138844258546893532214993666117722702463877026204624",
            "1"
        ],
        [
            "12282563786492051221220863019504107834872987144162405093912833624832473504126",
            "2098404497662286606968957419285970045028044455644658720985187205946176225636",
            "1"
        ],
        [
            "1990701565738088758270472967471263340707808628204302356692995713089340295959",
            "4710902959112092813812405997875645709469153185247079786406984810436621334836",
            "1"
        ],
        [
            "20358882933388981503171778761697392336011378779059025555927722043477769063258",
            "19015855458316650610909766042056506990773552974154423789621320056338171324109",
            "1"
        ],
        [
            "20882010929117143317945388885678484675687595287997043750607534940060968021588",
            "11586557172082174037613559244105184201710114582175280732260566723406709924275",
            "1"
        ],
        [
            "9866308320093007323457785354472236077116309736444536950583247217505300484593",
            "7621726862256096662846253511430079218096624239819015602672239587875065773680",
            "1"
        ],
        [
            "14027123489779385457612700332560563436358522575256251872455086560940806515518",
            "10938955322537907189548948078384029109133599816409669950598646265343304376683",
            "1"
        ],
        [
            "8185779524540657541561125117577265603809435796152263318353366879537563587361",
            "7022890698869206227386505409956869964786133909878013184769185704625348906859",
            "1"
        ],
        [
            "11611413113751908909193648245064739218553980961929170910199270975967104957038",
            "18994807587760619856245913328685591005051029724453337667407306111138944756694",
            "1"
        ],
        [
            "200383746952988761639379177517104787510472386926528110614397950418667358661",
            "20007848431425763869830663340890269703980870987344402378604194352912831137056",
            "1"
        ],
        [
            "328413860030399674842447170312944751562586291423774720425356928068580343472",
            "5189648959630633293821012021210812639351882790811543893302480708749969871675",
            "1"
        ],
        [
            "6012328917803371026931141367320642434394368982571440096775691385288621172219",
            "3144007704082241276171331516247837779546266689067323035946808770824524079278",
            "1"
        ],
        [
            "6432946433062452526687536616554972830856614963273241146116338471741671687252",
            "15585047391247849588392219751347369098681511169371119693472990059654876497118",
            "1"
        ]
    ]
}
