import { booleanToBigInt, generateSnarkMessageHash, numberToBigInt, uuidToBigInt } from "@pcd/util";
import urlJoin from "url-join";
export function semaphoreIdToBigInt(v) {
    return BigInt(v);
}
/**
 * Converts the property values of the {@link ITicketData} object to
 * a list of big integers ({@link SerializedTicket}).
 */
export function ticketDataToBigInts(data) {
    return [
        uuidToBigInt(data.ticketId),
        uuidToBigInt(data.eventId),
        uuidToBigInt(data.productId),
        numberToBigInt(data.timestampConsumed),
        numberToBigInt(data.timestampSigned),
        semaphoreIdToBigInt(data.attendeeSemaphoreId),
        booleanToBigInt(data.isConsumed),
        booleanToBigInt(data.isRevoked),
        numberToBigInt(data.ticketCategory),
        generateSnarkMessageHash(data.attendeeEmail),
        generateSnarkMessageHash(data.attendeeName),
        numberToBigInt(0)
    ];
}
/**
 * Returns the ticket inside of this PCD if it exists.
 */
export function getEdDSATicketData(pcd) {
    return pcd?.claim?.ticket;
}
/**
 * Returns the public key this PCD was signed with if it exists.
 */
export function getPublicKey(pcd) {
    return pcd?.proof?.eddsaPCD?.claim?.publicKey;
}
const INVALID_TICKET_QR_CODE_COLOR = "#d3d3d3";
/**
 * The QR code's color to be shown when a ticket is
 * not valid, i.e. undefined, consumed or revoked.
 */
export function getQRCodeColorOverride(pcd) {
    const ticketData = getEdDSATicketData(pcd);
    if (!ticketData || ticketData.isRevoked) {
        return INVALID_TICKET_QR_CODE_COLOR;
    }
    // Otherwise, don't override and use default.
    return undefined;
}
function makeIdBasedVerifyLink(baseUrl, ticketId) {
    return urlJoin(baseUrl, `?id=${ticketId}`);
}
export function linkToTicket(baseUrl, ticketId, eventId) {
    const encodedId = Buffer.from(JSON.stringify({
        ticketId: ticketId,
        eventId: eventId,
        timestamp: Date.now().toString()
    })).toString("base64");
    return makeIdBasedVerifyLink(baseUrl, encodedId);
}
